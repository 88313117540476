import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, Row } from "reactstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { Box, TextField, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { API_URL } from "../../../config";
import { Multiselect } from "multiselect-react-dropdown";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertFromHTML, convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: "black",
    fontSize: "15px",
  },
  bin1: {
    margin: "5px 0 !important",
    padding: "15px",
    width: "100%",
    border: "1px solid #7c13867d !important",
    borderRadius: "8px ",
    background: "transparent",
    color: "#fff",
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&:focus-Visible": {
      outline: "none !important",
      border: "none !important",
    },
    "&:hover": {
      border: "none",
    },
    "& .MuiInputBase-root": {
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },

    "&:focus": {
      boxShadow: "none !important",
      border: "1px solid #7c13867d !important",
      borderRadius: "10px !important ",
    },
    "& input": {
      padding: "10px",
      fontSize: "15px",
      borderRadius: "10px",
      fontWeight: "500",
      "&::placeholder": {
        color: "#7C1386 !important",
        fontSize: "15px",
      },
    },
    "& textarea": {
      fontSize: "16px",
      color: "#7c13867d !important",
      padding: "10px !important",
      fontWeight: "500",
    },
  },
  btns: {
    textAlign: "center",
    margin: "10px 0",
    "& button": {
      fontSize: "15px",
      background: "#7C1386 !important",
      borderRadius: "10px",
      textTransform: "capitalize",
      color: "#fff",
      padding: "10px 30px",
      transition: "0.3s all !important",

      "&.Mui-disabled": {
        cursor: "not-allowed !important",
        pointerEvents: "auto !important",
        color: "rgb(255 255 255 / 38%) !important",
        "&:hover": {
          opacity: "1",
          transform: "scale(1) !important",
        },
      },
    },
  },
  error: {
    color: "#f14668",
    fontSize: "14px !important",
    paddingTop: "3px",
    wordBreak: "break-all",
  },
}));

const EditProduct = ({
  openEdit,
  handleEditPop,
  updateData,
  categroyData,
  getProduct,
  handleMesssage,
  handleSuccesssPop,
  handleErrorPop,
}) => {
  const [convertedContent, setConvertedContent] = useState(null);
  const [isDescription, setIsDescription] = useState(false);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(updateData[0]?.description || "")
  );

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };
  const [selectCategroy, setSelectCategroy] = useState(updateData[0]?.category || '');
  const [selecteValue, setSelecteValue] = useState([]);
  const [valuesSelected, setValuesSelected] = useState(
    updateData[0]?.accessory_data || ""
  );
  const [isSelecte, setIsSelecte] = useState(false);

  const uniqueArray = updateData[0]?.accessory_data.filter(
    (obj, index, self) =>
      index === self.findIndex((o) => o.accessory_id === obj.accessory_id)
  );

  const handleChange = (e) => {
    setIsSelecte(false);
    setValuesSelected(e);
  };

  const classes = useStyles();
  useEffect(() => {
    if(openEdit){
      setSelectCategroy(updateData[0]?.category);
    }
    formik.setValues({
      name: updateData[0]?.name || "",
      availability: updateData[0]?.availability || "",
      weight: updateData[0]?.weight || "",
      price: updateData[0]?.price || "",
      subtitle: updateData[0]?.subtitle || "",
      insured_value: updateData[0]?.insured_value || "",
    });

        // Set the editor state separately
        setEditorState(EditorState.createWithContent(convertFromHTML(updateData[0]?.description || "")));

        // Set the selected values for Multiselect
        setValuesSelected(updateData[0]?.accessory_data || "");
    
        // Set the selected category

    
  }, [updateData]);

  useEffect(() => {
    getAllProduct();
  }, []);

  const getAllProduct = () => {
    fetch(API_URL + "/get/all/product", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      if (response.status == 200) {
        const resp = await response.json();
        let _temp = [];
        resp?.data?.map((item, i) => {
          _temp.push({
            id: item.id,
            name: item.name,
          });
        });
        setSelecteValue(_temp);
      } else if (response.status == 401) {
        logout();
      } else {
        alert("network error");
      }
    });
  };

  const validationSchema = yup.object({
    name: yup.string().required("Required").trim(),
    availability: yup.string().required("Required").trim(),
    insured_value: yup.string().required("Required").trim(),
    subtitle: yup.string().trim(),
    weight: yup.number().required("Required"),
    price: yup.number().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      weight: "",
      availability: "",
      price: "",
      insured_value: "",
      subtitle: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // debugger
      if (valuesSelected.length === 0) {
        let arrayValue = [];
        for (let i = 0; i < uniqueArray.length; i++) {
          arrayValue.push(uniqueArray[i].accessory_id);
        }
        handlUpdateProduct({
          category: selectCategroy,
          ...values,
          accessory_id: arrayValue,
          description: convertedContent,
        });
      } else {
        let accessory_id = [];
        for (let i = 0; i < valuesSelected.length; i++) {
          if (valuesSelected[i].id) {
            accessory_id.push(valuesSelected[i].id);
          } else {
            accessory_id.push(valuesSelected[i].accessory_id);
          }
        }
        handlUpdateProduct({
          category: selectCategroy,
          description: convertedContent,
          ...values,
          accessory_id: accessory_id,
        });
      }
    },
  });

  const handleSelect = (event) => {
    setSelectCategroy(event.target.value);
  };

  const handlUpdateProduct = async (value) => {
    // debugger
    try {
      const response = await axios.put(
        `${API_URL}/update/product/by/${updateData[0]?.id}`,
        value
      );

      if (response.status === 200) {
        const resp = response.data;
        handleMesssage(resp.message);
        getProduct();
        handleEditPop();
        handleSuccesssPop();
      }
    } catch (error) {
      if (error.response.status === 400 || error.response.status === 404) {
        handleMesssage(error.response.data.message);
        handleErrorPop();
      } else {
        throw new Error("Network error");
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={openEdit}
        toggle={handleEditPop}
        className="connect-boxEp"
        centered={true}
      >
        <ModalHeader toggle={handleEditPop}>
          <span className="ml-1 roititle">Edit Product</span>
        </ModalHeader>
        <ModalBody>
          <div className="changedate-box">
            <form onSubmit={formik.handleSubmit}>
            <Box sx={{
              display:'flex',
              justifyContent:'space-between',
              "@media(max-width:600px)": { 
                    flexDirection:'column'
                  }
            }}>


            <Box sx={{
              width:'48%',
              "@media(max-width:600px)": { width: "100% !important" }
            }}>

              <Box sx={{
                display:'flex',
                justifyContent:'space-between',
                "@media(max-width:600px)": { 
                  flexDirection:'column'
                 }
              }}>

                <Box
                  sx={{
                    width: "48%",
                    "@media(max-width:600px)": { width: "100% !important" },
                  }}
                >
                  <Typography
                    component={"label"}
                    sx={{ fontWeight: "600", color: "#7C1386" }}
                  >
                    Name<sup>*</sup>
                  </Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="name"
                    name="name"
                    type="text"
                    className={classes.bin1}
                    value={formik.values.name}
                    placeholder="Enter product name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="standard"
                  />
                  {formik.touched.name && formik.errors.name && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.name}
                    </Typography>
                  )}
                </Box>

                <Box
                  sx={{
                    width: "48%",
                    "@media(max-width:600px)": { width: "100% !important" },
                  }}
                >
                  <Typography
                    component={"label"}
                    sx={{ fontWeight: "600", color: "#7C1386" }}
                  >
                    Subtitle
                  </Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="subtitle"
                    name="subtitle"
                    type="text"
                    className={classes.bin1}
                    value={formik.values.subtitle}
                    placeholder="Enter product subtitle"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="standard"
                  />
                  {formik.touched.subtitle && formik.errors.subtitle && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.subtitle}
                    </Typography>
                  )}
                </Box>

              </Box>

              <Box sx={{
                display:'flex',
                justifyContent:'space-between',
                "@media(max-width:600px)": { 
                  flexDirection:'column'
                 }
              }}>

                <Box
                  sx={{
                    width: "48%",
                    "@media(max-width:600px)": { width: "100% !important" },
                  }}
                >
                  <Typography
                    component={"label"}
                    sx={{ fontWeight: "600", color: "#7C1386" }}
                  >
                    Availability<sup>*</sup>
                  </Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="availability"
                    name="availability"
                    type="text"
                    className={classes.bin1}
                    value={formik.values.availability}
                    placeholder="Enter product availability"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="standard"
                  />
                  {formik.touched.availability &&
                    formik.errors.availability && (
                      <Typography variant="body1" className={classes.error}>
                        {formik.errors.availability}
                      </Typography>
                    )}
                </Box>

                <Box
                  sx={{
                    width: "48%",
                    "@media(max-width:600px)": { width: "100% !important" },
                  }}
                >
                  <Typography
                    component={"label"}
                    sx={{ fontWeight: "600", color: "#7C1386" }}
                  >
                    Price<sup>*</sup>
                  </Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="price"
                    name="price"
                    type="number"
                    className={classes.bin1}
                    value={formik.values.price}
                    placeholder="Enter product price."
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="standard"
                  />
                  {formik.touched.price && formik.errors.price && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.price}
                    </Typography>
                  )}
                </Box>

              </Box>

              <Box sx={{
                display:'flex',
                justifyContent:'space-between',
                "@media(max-width:600px)": { 
                  flexDirection:'column'
                 }
              }}>
                <Box
                  sx={{
                    width: "48%",
                    "@media(max-width:600px)": { width: "100% !important" },
                  }}
                >
                  <Typography
                    component={"label"}
                    sx={{ fontWeight: "600", color: "#7C1386" }}
                  >
                    Weight<sup>*</sup>
                  </Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="weight"
                    name="weight"
                    type="number"
                    className={classes.bin1}
                    value={formik.values.weight}
                    placeholder="Enter product weight"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="standard"
                  />
                  {formik.touched.weight && formik.errors.weight && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.weight}
                    </Typography>
                  )}
                </Box>

                <Box
                  sx={{
                    width: "48%",
                    "@media(max-width:600px)": { width: "100% !important" },
                  }}
                >
                  <Typography
                    component={"label"}
                    sx={{ fontWeight: "600", color: "#7C1386" }}
                  >
                    Insured value<sup>*</sup>
                  </Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="insured_value"
                    name="insured_value"
                    type="number"
                    className={classes.bin1}
                    value={formik.values.insured_value}
                    placeholder="Enter product Insured value"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="standard"
                  />
                  {formik.touched.insured_value &&
                    formik.errors.insured_value && (
                      <Typography variant="body1" className={classes.error}>
                        {formik.errors.insured_value}
                      </Typography>
                    )}
                </Box>

              </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    "@media(max-width:600px)": { width: "100% !important" },
                  }}
                >
                  <Typography
                    component={"label"}
                    sx={{ fontWeight: "600", color: "#7C1386" }}
                  >
                    Category<sup>*</sup>
                  </Typography>
                  {console.log(selectCategroy ," selectCategroy value ")}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    placeholder="Select Categroy"
                    value={selectCategroy}
                    label="Categroy"
                    onChange={(e) => handleSelect(e)}
                    sx={{
                      border: "1px solid purple",
                      "& .MuiSelect-select": {
                        padding: "10px !important",
                        border: "1px solid #7c13867d !important",
                      },
                    }}
                  >
                    {categroyData?.map((item, i) => (
                      <MenuItem value={item.id}>{item.category_name}</MenuItem>
                    ))}
                  </Select>
                </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  paddingTop: "0.4rem",
                  // "@media (max-width:600px)":{
                  //       paddingTop:'0rem'
                  //     },
                  "& .multiSelectContainer": {
                    border: "1px solid #7C1386",
                    borderRadius: "0.3rem",
                  },
                  "& .highlightOption": {
                    background: "#800080 !important",
                  },
                  "& .multiSelectContainer li:hover": {
                    background: "#800080 !important",
                  },
                  "& .chip": {
                    background: "#800080 !important",
                  },
                  "@media(max-width:600px)": { width: "100% !important" },
                }}
              >
                <Typography
                  component={"label"}
                  sx={{ fontWeight: "600", color: "#7C1386" }}
                >
                  Accessory
                </Typography>
                <Multiselect
                  options={selecteValue}
                  selectedValues={uniqueArray}
                  displayValue="name"
                  onSelect={handleChange}
                  onRemove={handleChange}
                />
                {isSelecte && (
                  <Typography component={"p"} className={classes.error}>
                    Required
                  </Typography>
                )}
              </Box>


              </Box>

              {/* right side code  */}

              <Box sx={{
                width:"48%",
                "@media(max-width:600px)": { width: "100% !important" }
              }}>

              <Box
                sx={{
                  width: "100%",
                  // paddingTop: "0.4rem",
                  "@media(max-width:600px)": { width: "100% !important", paddingTop: "0.4rem", },
                }}
              >
                <Typography
                  component={"label"}
                  sx={{ fontWeight: "600", color: "#7C1386", paddingBottom:'0.38rem' }}
                >
                  Description
                </Typography>

                <Box
                  sx={{
                    border: "1px solid purple",
                    borderRadius: "4px",
                    height: "363px",
                    width: "100%",
                    overflow:'auto',  
                    "& .rdw-editor-main": {
                      padding: "0rem 0.5rem",
                    },
                    "& a":{
                          color:'purple !important'
                        },
                        "& a:hover":{
                          color:'purple !important'
                        }
                  }}
                >
                  <Editor
                    editorState={editorState}
                    onEditorStateChange={handleEditorChange}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                  />
                </Box>
                {isDescription && (
                  <Typography component={"p"}>Required</Typography>
                )}
              </Box>
              </Box>

              </Box>


              <Box className={classes.btns}>
                <Button
                  type="submit"
                  disabled={!(formik.isValid && formik.dirty)}
                >
                  Update
                </Button>
              </Box>
            </form>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EditProduct;
