import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, Row } from "reactstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { Box, TextField, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { API_URL } from "../../../config";

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: "black",
    fontSize: "15px",
  },
  bin1: {
    margin: "5px 0 !important",
    padding: "15px",
    width: "100%",
    border: "1px solid #7c13867d !important",
    borderRadius: "8px ",
    background: "transparent",
    color: "#fff",
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&:focus-Visible": {
      outline: "none !important",
      border: "none !important",
    },
    "&:hover": {
      border: "none",
    },
    "& .MuiInputBase-root": {
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },

    "&:focus": {
      boxShadow: "none !important",
      border: "1px solid #7c13867d !important",
      borderRadius: "10px !important ",
    },
    "& input": {
      padding: "10px",
      fontSize: "15px",
      borderRadius: "10px",
      fontWeight: "500",
      "&::placeholder": {
        color: "#7C1386 !important",
        fontSize: "15px",
      },
    },
    "& textarea": {
      fontSize: "16px",
      color: "#7c13867d !important",
      padding: "10px !important",
      fontWeight: "500",
    },
  },
  btns: {
    textAlign: "center",
    margin: "10px 0",
    "& button": {
      fontSize: "15px",
      background: "#7C1386 !important",
      borderRadius: "10px",
      textTransform: "capitalize",
      color: "#fff",
      padding: "10px 30px",
      transition: "0.3s all !important",

      "&.Mui-disabled": {
        cursor: "not-allowed !important",
        pointerEvents: "auto !important",
        color: "rgb(255 255 255 / 38%) !important",
        "&:hover": {
          opacity: "1",
          transform: "scale(1) !important",
        },
      },
    },
  },
  error: {
    color: "#f14668",
    fontSize: "14px !important",
    paddingTop: "3px",
    wordBreak: "break-all",
  },
}));

const EditCategroy = ({
  openEdit,
  handleEditPop,
  updateData,
  getProduct,
  handleMesssage,
  handleSuccesssPop,
  handleErrorPop,
}) => {
  const classes = useStyles();
  useEffect(() => {
    formik.setValues({
      category_name: updateData[0]?.category_name || "",
      description: updateData[0]?.description || "",
    });
  }, [updateData]);

  const validationSchema = yup.object({
    category_name: yup.string().required("Required").trim(),
    description: yup.string().required("Required").trim(),
  });
  const formik = useFormik({
    initialValues: {
      category_name: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      handlUpdateCategroy(values);
    },
  });

  const handlUpdateCategroy = async (values) => {
    try {
      const response = await axios.put(
        `${API_URL}/update/product/category/by/${updateData[0]?.id}`,
        values
      );

      if (response.status === 200) {
        const resp = response.data;
        handleMesssage("Product Category updated successfully");
        getProduct();
        handleEditPop();
        handleSuccesssPop();
      }
    } catch (error) {
      if (error.response.status === 400 || error.response.status === 404) {
        handleMesssage(error.response.data.message);
        handleErrorPop();
      } else {
        throw new Error("Network error");
      }
    }
  };
  return (
    <>
      <Modal
        isOpen={openEdit}
        toggle={handleEditPop}
        // className="connect-box"
        centered={true}
        style={{ maxWidth: "500px !important" }}
      >
        <ModalHeader toggle={handleEditPop}>
          <span className="ml-1 roititle">Edit Category</span>
        </ModalHeader>
        <ModalBody>
          <div className="changedate-box">
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  gap: 5,
                  "@media(max-width:600px)": {
                    flexDirection: "column",
                    gap: 0,
                  },
                }}
              >
                <Box
                  sx={{
                    width: "48%",
                    "@media(max-width:600px)": { width: "100% !important" },
                  }}
                >
                  <Typography
                    component={"label"}
                    sx={{ fontWeight: "600", color: "#7C1386" }}
                  >
                    Name<sup>*</sup>
                  </Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="category_name"
                    name="category_name"
                    type="text"
                    className={classes.bin1}
                    value={formik.values.category_name}
                    placeholder="Enter category name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="standard"
                  />
                  {formik.touched.category_name &&
                    formik.errors.category_name && (
                      <Typography variant="body1" className={classes.error}>
                        {formik.errors.category_name}
                      </Typography>
                    )}
                </Box>
                <Box
                  sx={{
                    width: "48%",
                    "@media(max-width:600px)": { width: "100% !important" },
                  }}
                >
                  <Typography
                    component={"label"}
                    sx={{ fontWeight: "600", color: "#7C1386" }}
                  >
                    Description<sup>*</sup>
                  </Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="description"
                    name="description"
                    type="text"
                    className={classes.bin1}
                    value={formik.values.description}
                    placeholder="Enter category description"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="standard"
                  />
                  {formik.touched.description && formik.errors.description && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.description}
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box className={classes.btns}>
                <Button
                  type="submit"
                  disabled={!(formik.isValid && formik.dirty)}
                >
                  Update
                </Button>
              </Box>
            </form>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EditCategroy;
