import { Typography } from "@mui/material";
import React from "react";
import { Modal, ModalHeader, ModalBody, Row } from "reactstrap";

const ErrorPop = ({ openError, handleErrorPop, message }) => {
  return (
    <>
      <Modal
        isOpen={openError}
        toggle={handleErrorPop}
        // className="connect-box"
        centered={true}
        style={{ maxWidth: "500px !important" }}
      >
        <ModalHeader toggle={handleErrorPop}>
          <span className="ml-1 roititle">Error</span>
        </ModalHeader>
        <ModalBody>
          <div className="changedate-box">
            <Typography
              component={"p"}
              sx={{ textAlign: "center", padding: "1rem" }}
            >
              {message}
            </Typography>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ErrorPop;
