import React from "react";
import { Modal, ModalHeader, ModalBody, Row } from "reactstrap";
import { Box } from "@mui/material";
import ImageView from "../ImageView/ImageView";

const ImagePop = ({ openError, handleErrorPop, imageId, getProduct }) => {
  return (
    <>
      <Modal
        isOpen={openError}
        toggle={handleErrorPop}
        // className="connect-box"
        centered={true}
        style={{ maxWidth: "500px !important" }}
      >
        <ModalHeader toggle={handleErrorPop}>
          <span className="ml-1 roititle">Product Images</span>
        </ModalHeader>
        <ModalBody>
          <Box>
            <ImageView imageId={imageId} getProduct={getProduct} />
          </Box>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ImagePop;
