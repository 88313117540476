import React from "react";
import Editdevice from "./Editdevice";


class Rightsidebar extends React.Component {
    render() {
        return (
            <div>
                <Editdevice />
            </div>
        )
    }
}

export default Rightsidebar;