import { TableCell, TableRow } from "@mui/material";
import React, { useState } from "react";
import { API_URL } from "../../../config";
import OrderView from "../Order/OrderView";

const AffiliateOrderTableRow = ({ data, key,comission }) => {
  const convertDateString = (inputString) => {
    const date = new Date(inputString);

    const year = date.getUTCFullYear().toString(); // Get last two digits of the year
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1 and pad with 0 if needed
    const day = String(date.getUTCDate()).padStart(2, "0"); // Pad day with 0 if needed

    return `${year}-${month}-${day}`;
  };
  const [dataById, setDataById] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const handleEditPop = () => setOpenEdit(!openEdit);
  const handleEditOpen = () => setOpenEdit(true);
  const handleView = () => {
    handleEditOpen();
    getOrdersById(data?.id);
  };

  const getOrdersById = (id) => {
    fetch(API_URL + `/get/order/by/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      if (response.status == 200) {
        const resp = await response.json();
        // debugger
        let _temp = [];
        resp.items.map((item, i) => {
          _temp.push({
            shipping_address_info: JSON.parse(item.shipping_address_info),
            billing_address_info: JSON.parse(item.billing_address_info),
            total_amount: item.total_amount,
            product_total_price: item.product_total_price,
            Shipping_fee: item.Shipping_fee,
            items: item.items,
            shipping_method: item.shipping_method,
            created: convertDateString(item.created),
          });
        });
        setDataById(_temp);
      } else {
        alert("network error");
      }
    });
  };

  return (
    <>
      {" "}
      <TableRow key={key}>
        <TableCell
          align="right"
          onClick={handleView}
          sx={{
            color: "blue !important",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          {data?.id}
        </TableCell>
        <TableCell
          onClick={handleView}
          sx={{
            color: "blue !important",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          align="right"
        >
          {convertDateString(data?.created)}
        </TableCell>
        <TableCell
          onClick={handleView}
          sx={{
            color: "blue !important",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          align="right"
        >
          $ {data?.total_amount}
        </TableCell>
        <TableCell
          onClick={handleView}
          sx={{
            color: "blue !important",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          align="right"
        >
          $ {parseFloat(parseFloat(data?.total_amount)*(parseFloat(comission)/100)).toFixed(3)}
        </TableCell>
      </TableRow>
      <OrderView
        handleEditPop={handleEditPop}
        openEdit={openEdit}
        dataById={dataById}
      />
    </>
  );
};

export default AffiliateOrderTableRow;
