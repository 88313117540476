import React, { useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, Row } from "reactstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { Box, TextField, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { API_URL } from "../../../config";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: "black",
    fontSize: "15px",
  },
  bin1: {
    margin: "5px 0 !important",
    padding: "15px",
    width: "100%",
    border: "1px solid #7c13867d !important",
    borderRadius: "8px ",
    background: "transparent",
    color: "#fff",
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&:focus-Visible": {
      outline: "none !important",
      border: "none !important",
    },
    "&:hover": {
      border: "none",
    },
    "& .MuiInputBase-root": {
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },

    "&:focus": {
      boxShadow: "none !important",
      border: "1px solid #7c13867d !important",
      borderRadius: "10px !important ",
    },
    "& input": {
      padding: "10px",
      fontSize: "15px",
      borderRadius: "10px",
      fontWeight: "500",
      "&::placeholder": {
        color: "#7C1386 !important",
        fontSize: "15px",
      },
    },
    "& textarea": {
      fontSize: "16px",
      color: "#7c13867d !important",
      padding: "10px !important",
      fontWeight: "500",
    },
  },
  btns: {
    textAlign: "center",
    margin: "10px 0",
    "& button": {
      fontSize: "15px",
      background: "#7C1386 !important",
      borderRadius: "10px",
      textTransform: "capitalize",
      color: "#fff",
      padding: "10px 30px",
      transition: "0.3s all !important",

      "&.Mui-disabled": {
        cursor: "not-allowed !important",
        pointerEvents: "auto !important",
        color: "rgb(255 255 255 / 38%) !important",
        "&:hover": {
          opacity: "1",
          transform: "scale(1) !important",
        },
      },
    },
  },
  error: {
    color: "#f14668",
    fontSize: "14px !important",
    paddingTop: "3px",
    wordBreak: "break-all",
  },
}));

const EditFaqCategroy = ({
  openEdit,
  handleEditPop,
  updateData,
  getFaqcategory,
  handleMesssage,
  handleSuccesssPop,
  handleErrorPop,
}) => {

  const classes = useStyles();
  useEffect(() => {
    formik.setValues({
        category: updateData[0]?.name || "",
    });
  }, [updateData]);

  const validationSchema = yup.object({
    category: yup.string().required("Required").trim(),
  });

  const formik = useFormik({
    initialValues: {
        category: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      handlUpdateFaqCategory(values);
    },
  });



  const handlUpdateFaqCategory = async (value) => {
    try {
      const response = await axios.post(
        `${API_URL}/update/faqCategory/${updateData[0]?.id}`,
        
          {
          name:value?.category,
          status:1
        }
      
      );

      if (response.status === 200) {
        const resp = response.data;
        handleMesssage(resp.message);
        getFaqcategory();
        handleEditPop();
        handleSuccesssPop();
      }
    } catch (error) {
      if (error.response.status === 400 || error.response.status === 404) {
        handleMesssage(error.response.data.message);
        handleErrorPop();
      } else {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={openEdit}
        toggle={handleEditPop}
        className="connect-boxSupport"
        centered={true}
      >
        <ModalHeader toggle={handleEditPop}>
          <span className="ml-1 roititle">Edit FAQ Category</span>
        </ModalHeader>
        <ModalBody>
          <div className="changedate-box">
            <form onSubmit={formik.handleSubmit}>
            <Box sx={{
              display:'flex',
              justifyContent:'space-between',
              "@media(max-width:600px)": { 
                    flexDirection:'column'
                  }
            }}>


            <Box sx={{
              width:'100%',
              "@media(max-width:600px)": { width: "100% !important" }
            }}>

              <Box sx={{
                display:'flex',
                justifyContent:'space-between',
                "@media(max-width:600px)": { 
                  flexDirection:'column'
                 }
              }}>

                <Box
                  sx={{
                    width: "100%",
                    "@media(max-width:600px)": { width: "100% !important" },
                  }}
                >
                  <Typography
                    component={"label"}
                    sx={{ fontWeight: "600", color: "#7C1386" }}
                  >
                    Category<sup>*</sup>
                  </Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="category"
                    name="category"
                    type="text"
                    className={classes.bin1}
                    value={formik.values.category}
                    placeholder="Enter category"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="standard"
                  />
                  {formik.touched.category && formik.errors.category && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.category}
                    </Typography>
                  )}
                </Box>


              </Box>





              </Box>


              </Box>


              <Box className={classes.btns}>
                <Button
                  type="submit"
                  disabled={!(formik.isValid && formik.dirty)}
                >
                  Update
                </Button>
              </Box>
            </form>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EditFaqCategroy;
