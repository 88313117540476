import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { Box, TextField, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { API_URL } from "../../../config";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertFromHTML, convertToHTML } from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const useStyles = makeStyles((theme) => ({

  bin1: {
    margin: "5px 0 !important",
    padding: "15px",
    width: "100%",
    border: "1px solid #7c13867d !important",
    borderRadius: "8px ",
    background: "transparent",
    color: "#fff",
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&:focus-Visible": {
      outline: "none !important",
      border: "none !important",
    },
    "&:hover": {
      border: "none",
    },
    "& .MuiInputBase-root": {
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },

    "&:focus": {
      boxShadow: "none !important",
      border: "1px solid #7c13867d !important",
      borderRadius: "10px !important ",
    },
    "& input": {
      padding: "10px",
      fontSize: "15px",
      borderRadius: "10px",
      fontWeight: "500",
      "&::placeholder": {
        color: "#7C1386 !important",
        fontSize: "15px",
      },
    },
    "& textarea": {
      fontSize: "16px",
      color: "#7c13867d !important",
      padding: "10px !important",
      fontWeight: "500",
    },
  },
  btns: {
    textAlign: "center",
    margin: "10px 0",
    "& button": {
      fontSize: "15px",
      background: "#7C1386 !important",
      borderRadius: "10px",
      textTransform: "capitalize",
      color: "#fff",
      padding: "10px 30px",
      transition: "0.3s all !important",

      "&.Mui-disabled": {
        cursor: "not-allowed !important",
        pointerEvents: "auto !important",
        color: "rgb(255 255 255 / 38%) !important",
        "&:hover": {
          opacity: "1",
          transform: "scale(1) !important",
        },
      },
    },
  },
  error: {
    color: "#f14668",
    fontSize: "14px !important",
    paddingTop: "3px",
    wordBreak: "break-all",
  },
}));

const EditFaq = ({
  openEdit,
  handleEditPop,
  updateData,
  categroyData,
  getFaq,
  handleMesssage,
  handleSuccesssPop,
  handleErrorPop,
}) => {
  const [convertedContent, setConvertedContent] = useState(null);
  const [isDescription, setIsDescription] = useState(false);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(updateData[0]?.answer || "")
  );

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };
  const [selectCategroy, setSelectCategroy] = useState(
    updateData[0]?.category || ""
  );




  const classes = useStyles();
  useEffect(() => {
    if(openEdit){
      setSelectCategroy(updateData[0]?.category);
    }
    formik.setValues({
      question: updateData[0]?.question || "",

    });
    setEditorState(EditorState.createWithContent(convertFromHTML(updateData[0]?.answer || "")));
  }, [updateData]);





  const validationSchema = yup.object({
    question: yup.string().required("Required").trim(),
  });

  const formik = useFormik({
    initialValues: {
      question: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if(editorState.length == 0){

        let data={
          category: selectCategroy,
          answer: convertedContent,
          question:values.question
        }
        handlUpdateFaq(data);
      }else{
        let data={
          category: selectCategroy,
          answer: updateData[0]?.answer,
          question:values.question
        }
        handlUpdateFaq(data);

      }
    },
  });

  const handleSelect = (event) => {
    setSelectCategroy(event.target.value);
  };

  const handlUpdateFaq = async (value) => {
    debugger
    try {
      const response = await axios.post(
        `${API_URL}/update/faq/${updateData[0]?.id}`,
        value
      );
      if (response.status == 200) {
        const resp = response.data;
        handleMesssage(resp.message);
        getFaq(0);
        handleEditPop();
        handleSuccesssPop();
      }
    } catch (error) {
      if (error.response.status === 400 || error.response.status === 404) {
        handleMesssage(error.response.data.message);
        handleErrorPop();
      } else {
        throw new Error("Network error");
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={openEdit}
        toggle={handleEditPop}
        className="connect-boxSupport"
        centered={true}
      >
        <ModalHeader toggle={handleEditPop}>
          <span className="ml-1 roititle">Edit FAQ</span>
        </ModalHeader>
        <ModalBody>
          <div className="changedate-box">
            <form onSubmit={formik.handleSubmit}>
            <Box sx={{
              display:'flex',
              justifyContent:'space-between',
              "@media(max-width:600px)": { 
                    flexDirection:'column'
                  }
            }}>


            <Box sx={{
              width:'100%',
              "@media(max-width:600px)": { width: "100% !important" }
            }}>

              <Box sx={{
                display:'flex',
                justifyContent:'space-between',
                "@media(max-width:600px)": { 
                  flexDirection:'column'
                 }
              }}>

                <Box
                  sx={{
                    width: "100%",
                    "@media(max-width:600px)": { width: "100% !important" },
                  }}
                >
                  <Typography
                    component={"label"}
                    sx={{ fontWeight: "600", color: "#7C1386" }}
                  >
                    Question<sup>*</sup>
                  </Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="question"
                    name="question"
                    type="text"
                    className={classes.bin1}
                    value={formik.values.question}
                    placeholder="Enter question"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="standard"
                  />
                  {formik.touched.question && formik.errors.question && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.question}
                    </Typography>
                  )}
                </Box>


              </Box>




                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    "@media(max-width:600px)": { width: "100% !important" },
                  }}
                >
                  <Typography
                    component={"label"}
                    sx={{ fontWeight: "600", color: "#7C1386" }}
                  >
                    Category<sup>*</sup>
                  </Typography>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    placeholder="Select Categroy"
                    value={selectCategroy}
                    label="Categroy"
                    onChange={(e) => handleSelect(e)}
                    sx={{
                      border: "1px solid purple",
                      "& .MuiSelect-select": {
                        padding: "10px !important",
                        border: "1px solid #7c13867d !important",
                      },
                    }}
                  >
                    {categroyData?.map((item, i) => (
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </Box>


                <Box
                sx={{
                  width: "100%",
                  paddingTop: "0.6rem",
                  "@media(max-width:600px)": { width: "100% !important", paddingTop: "0.4rem", },
                }}
              >
                <Typography
                  component={"label"}
                  sx={{ fontWeight: "600", color: "#7C1386", paddingBottom:'0.38rem' }}
                >
                  Answer
                </Typography>

                <Box
                  sx={{
                    border: "1px solid purple",
                    borderRadius: "4px",
                    height: "300px",
                    // overflowY:'auto',
                    width: "100%",
                    "& .rdw-editor-main": {
                      padding: "0rem 0.5rem",
                      height:"182px",
                          overflowY:'auto',
                    },
                    "& a":{
                          color:'purple !important'
                        },
                        "& a:hover":{
                          color:'purple !important'
                        }
                  }}
                >
                  <Editor
                    editorState={editorState}
                    onEditorStateChange={handleEditorChange}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                  />
                </Box>
                {isDescription && (
                  <Typography component={"p"}>Required</Typography>
                )}
              </Box>



              </Box>


              </Box>


              <Box className={classes.btns}>
                <Button
                  type="submit"
                  disabled={!(formik.isValid && formik.dirty)}
                >
                  Update
                </Button>
              </Box>
            </form>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EditFaq;
