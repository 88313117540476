import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, Row } from "reactstrap";
import { Box, Typography } from "@mui/material";
import OrderTable from "./OrderTable";

const OrderView = ({ openEdit, handleEditPop, dataById }) => {
  console.log(dataById, "dataById")
  let billing_address_info = dataById[0]?.billing_address_info;
  let shipping_address_info = dataById[0]?.shipping_address_info;


  const [billData, setBillData] = useState();
  const [transactionData, setTransactionData] = useState();
  useEffect(() => {}, [dataById]);

  return (
    <>
      <Modal
        isOpen={openEdit}
        toggle={handleEditPop}
        className="connect-boxorder"
        centered={true}
        // style={{ maxWidth: "500px !important" }}
      >
        <ModalHeader toggle={handleEditPop}>
          <span className="ml-1 roititle">Order Details</span>
        </ModalHeader>
        <ModalBody>
          <Box sx={{ height: "500px", overflow: "auto" }}>
            <Box
              sx={{
                display: "flex",
                "@media(max-width:600px)": { flexDirection: "column" },
              }}
            >
              <Box
                sx={{
                  flexDirection: "column",
                  width: "58%",
                  display: "flex",
                  "@media(max-width:900px)": { width: "100% !important" },
                }}
              >
                <Box>
                  <Typography
                    component={"h6"}
                    sx={{
                      fontWeight: 600,
                      paddingBottom: "0.2rem",
                      paddingLeft: "0.3rem",
                    }}
                  >
                    Billing Details
                  </Typography>
                  <Typography component={"hr"} />
                  <Box
                    sx={{
                      padding: "1rem 0rem",
                    }}
                  >
                    {/* row 1 */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        "@media(max-width:600px)": { flexDirection: "column" },
                      }}
                    >
                      {/* left side */}
                      <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": {
                            width: "100% !important",
                          },
                        }}
                      >
                        <Typography
                          component={"p"}
                          sx={{
                            fontWeight: 600,
                            textTransform: "capitalize",
                            width: "53%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                        
                        Customer Name :
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          {" "}
                          {billing_address_info?.fname}{" "}
                          {billing_address_info?.lname}
                        </Typography>
                      </Box>

                      {/* right side */}
                      <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": {
                            width: "100% !important",
                          },
                        }}
                      >
                        <Typography
                          component={"p"}
                          sx={{
                            fontWeight: 600,
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          Phone :
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          {" "}
                          {billing_address_info?.phone}
                        </Typography>
                      </Box>
                    </Box>

                    {/* row 2 */}

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                         "@media(max-width:600px)": { flexDirection: "column" },
                      }}
                    >
                      {/* left side */}
                      <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": {
                            width: "100% !important",
                          },
                        }}
                      >
                        <Typography
                          component={"p"}
                          sx={{
                            fontWeight: 600,
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          Company :
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          {" "}
                          {billing_address_info?.company}
                        </Typography>
                      </Box>

                      {/* right side */}
                      <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": {
                            width: "100% !important",
                          },
                        }}
                      >
                        <Typography
                          component={"p"}
                          sx={{
                            fontWeight: 600,
                            width: "48%",
                            "@media(max-width:600px)": {
                              width: "100% !important",
                            },
                            "@media(max-width:900px)": { width: "65%", wordBreak:'break-all' },
                          }}
                        >
                          State/Country :
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          {" "}
                          {billing_address_info?.state},{" "}
                          {billing_address_info?.country}
                        </Typography>
                      </Box>
                    </Box>

                    {/* row 3 */}

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        "@media(max-width:600px)": { flexDirection: "column" },
                      }}
                    >
                      {/* left side */}
                      <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": {
                            width: "100% !important",
                          },
                        }}
                      >
                        <Typography
                          component={"p"}
                          sx={{
                            fontWeight: 600,
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          Address 1 :
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          {" "}
                          {billing_address_info?.address_1}
                        </Typography>
                      </Box>

                      {/* right side */}
                      <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": {
                            width: "100% !important",
                          },
                        }}
                      >
                        <Typography
                          component={"p"}
                          sx={{
                            fontWeight: 600,
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          Address 2 :
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          {" "}
                          {billing_address_info?.address_2}
                        </Typography>
                      </Box>
                    </Box>

                    {/* row 3 */}

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        "@media(max-width:600px)": { flexDirection: "column" },
                      }}
                    >
                      {/* left side */}
                      <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": {
                            width: "100% !important",
                          },
                        }}
                      >
                        <Typography
                          component={"p"}
                          sx={{
                            fontWeight: 600,
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          Address 3 :
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          {" "}
                          NA
                          {/* {billing_address_info?.address_1} */}
                        </Typography>
                      </Box>

                      {/* right side */}
                      <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": {
                            width: "100% !important",
                          },
                        }}
                      >
                        <Typography
                          component={"p"}
                          sx={{
                            fontWeight: 600,
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          Address 4 :
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          {" "}
                          NA
                          {/* {billing_address_info?.address_2} */}
                        </Typography>
                      </Box>
                    </Box>

                    {/* row 4 */}

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        "@media(max-width:600px)": { flexDirection: "column" },
                      }}
                    >
                      {/* left side */}
                      <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": {
                            width: "100% !important",
                          },
                        }}
                      >
                        <Typography
                          component={"p"}
                          sx={{
                            fontWeight: 600,
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          City :
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          {" "}
                          {billing_address_info?.city}
                        </Typography>
                      </Box>

                      {/* right side */}

                      <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": {
                            width: "100% !important",
                          },
                        }}
                      >
                        <Typography
                          component={"p"}
                          sx={{
                            fontWeight: 600,
                            width: "48%",
                            "@media(max-width:600px)": {
                              width: "100% !important",
                            },
                            "@media(max-width:900px)": { width: "51%" },
                          }}
                        >
                          Postal Code  :
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          {" "}
                          {billing_address_info?.postal}
                        </Typography>
                      </Box>
                    </Box>

                    {/* row 5 */}

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        "@media(max-width:600px)": { flexDirection: "column" },
                      }}
                    ></Box>

                    {/* row 6.1 */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        "@media(max-width:600px)": { flexDirection: "column" },
                      }}
                    ></Box>

                    {/* row 6 */}

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        "@media(max-width:600px)": { flexDirection: "column" },
                      }}
                    >
                      {/* left side */}
                      <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": {
                            width: "100% !important",
                          },
                        }}
                      >
                        <Typography
                          component={"p"}
                          sx={{
                            fontWeight: 600,
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          Customer Email :
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{
                            width: "57%",
                            wordWrap:'break-word',
                            "@media(max-width:600px)": {
                              width: "100%",
                              wordBreak: "break-all",
                            },
                          }}
                        >
                          {" "}
                          {billing_address_info?.email}
                        </Typography>
                      </Box>

                      {/* right side */}
                      <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": {
                            width: "100% !important",
                          },
                        }}
                      >
                        <Typography
                          component={"p"}
                          sx={{
                            fontWeight: 600,
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          Order Date :
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          {" "}
                          {dataById[0]?.created}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {/* box 2 */}
                <Box>
                  <Typography
                    component={"h6"}
                    sx={{
                      fontWeight: 600,
                      paddingBottom: "0.2rem",
                    }}
                  >
                    Shipping Details
                  </Typography>
                  <Typography component={"hr"} />
                  <Box
                    sx={{
                      padding: "1rem 0rem",
                    }}
                  >
                    {/* row 1 */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        "@media(max-width:600px)": { flexDirection: "column" },
                      }}
                    >
                      {/* left side */}
                      <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": {
                            width: "100% !important",
                          },
                        }}
                      >
                        <Typography
                          component={"p"}
                          sx={{
                            fontWeight: 600,
                            textTransform: "capitalize",
                            width: "53%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                         Customer Name :
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          {" "}
                          {shipping_address_info?.fname}{" "}
                          {shipping_address_info?.lname}
                        </Typography>
                      </Box>

                      {/* right side */}
                      {/* <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": {
                            width: "100% !important",
                          },
                        }}
                      >
                        <Typography
                          component={"p"}
                          sx={{
                            fontWeight: 600,
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          Postal Code :
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          {" "}
                          {shipping_address_info?.postal}
                        </Typography>
                      </Box> */}
                      <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": {
                            width: "100% !important",
                          },
                        }}
                      >
                        <Typography
                          component={"p"}
                          sx={{
                            fontWeight: 600,
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          Phone :
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          {" "}
                          {shipping_address_info?.phone}
                        </Typography>
                      </Box>

                      {/* <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": { width: "100% !important" },
                        }}
                      >
                        <Typography component={"p"} sx={{ fontWeight: 600, width:"48%", "@media(max-width:600px)":{width:'100%'} }}>
                          Last Name :
                        </Typography>
                        <Typography component={"p"} sx={{width:"48%", "@media(max-width:600px)":{width:'100%'}}}>
                          {" "}
                          {shipping_address_info?.lname}
                        </Typography>
                      </Box> */}
                    </Box>

                    {/* row 2 */}

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        "@media(max-width:600px)": { flexDirection: "column" },
                      }}
                    >
                      {/* left side */}
                      <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": {
                            width: "100% !important",
                          },
                        }}
                      >
                        <Typography
                          component={"p"}
                          sx={{
                            fontWeight: 600,
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          Company :
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          {" "}
                          {shipping_address_info?.company}
                        </Typography>
                      </Box>

                      {/* right side */}
                      <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": {
                            width: "100% !important",
                          },
                        }}
                      >
                        <Typography
                          component={"p"}
                          sx={{
                            fontWeight: 600,
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          State /Country :
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          {" "}
                          {shipping_address_info?.state},{" "}
                          {shipping_address_info?.country}
                        </Typography>
                      </Box>
                    </Box>

                    {/* row 3 */}

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        "@media(max-width:600px)": { flexDirection: "column" },
                      }}
                    >
                      {/* left side */}
                      <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": {
                            width: "100% !important",
                          },
                        }}
                      >
                        <Typography
                          component={"p"}
                          sx={{
                            fontWeight: 600,
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          Address 1 :
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          {" "}
                          {shipping_address_info?.address_1}
                        </Typography>
                      </Box>

                      {/* right side */}
                      <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": {
                            width: "100% !important",
                          },
                        }}
                      >
                        <Typography
                          component={"p"}
                          sx={{
                            fontWeight: 600,
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          Address 2 :
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          {" "}
                          {shipping_address_info?.address_2}
                        </Typography>
                      </Box>
                    </Box>

                    {/* row 3 */}

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        "@media(max-width:600px)": { flexDirection: "column" },
                      }}
                    >
                      {/* left side */}
                      <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": {
                            width: "100% !important",
                          },
                        }}
                      >
                        <Typography
                          component={"p"}
                          sx={{
                            fontWeight: 600,
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          Address 3 :
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          {" "}
                          NA
                          {/* {billing_address_info?.address_1} */}
                        </Typography>
                      </Box>

                      {/* right side */}
                      <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": {
                            width: "100% !important",
                          },
                        }}
                      >
                        <Typography
                          component={"p"}
                          sx={{
                            fontWeight: 600,
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          Address 4 :
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          {" "}
                          NA
                          {/* {billing_address_info?.address_2} */}
                        </Typography>
                      </Box>
                    </Box>

                    {/* row 4 */}

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        "@media(max-width:600px)": { flexDirection: "column" },
                      }}
                    >
                      {/* left side */}
                      <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": {
                            width: "100% !important",
                          },
                        }}
                      >
                        <Typography
                          component={"p"}
                          sx={{
                            fontWeight: 600,
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          City :
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          {" "}
                          {shipping_address_info?.city}
                        </Typography>
                      </Box>

                      {/* right side */}
                      <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": {
                            width: "100% !important",
                          },
                        }}
                      >
                        <Typography
                          component={"p"}
                          sx={{
                            fontWeight: 600,
                            width: "51%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          Postal Code   :
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          {" "}
                          {shipping_address_info?.postal}
                        </Typography>
                      </Box>

                      {/* <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": { width: "100% !important" },
                        }}
                      >
                        <Typography component={"p"} sx={{ fontWeight: 600, width:"48%", "@media(max-width:600px)":{width:'100%'} }}>
                          State :
                        </Typography>
                        <Typography component={"p"} sx={{width:"48%", "@media(max-width:600px)":{width:'100%'}}}>
                          {" "}
                          {shipping_address_info?.state}
                        </Typography>
                      </Box> */}
                    </Box>

                    {/* row 5 */}

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        "@media(max-width:600px)": { flexDirection: "column" },
                      }}
                    >
                      {/* left side */}
                      {/* <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": { width: "100% !important" },
                        }}
                      >
                        <Typography component={"p"} sx={{ fontWeight: 600, width:"48%", "@media(max-width:600px)":{width:'100%'} }}>
                          Postal Code :
                        </Typography>
                        <Typography component={"p"} sx={{width:"48%", "@media(max-width:600px)":{width:'100%'}}}>
                          {" "}
                          {shipping_address_info?.postal}
                        </Typography>
                      </Box> */}
                      <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": {
                            width: "100% !important",
                          },
                        }}
                      >
                        <Typography
                          component={"p"}
                          sx={{
                            fontWeight: 600,
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                         Customer Email :
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{
                            width: "57%",
                            wordWrap:'break-word',
                            "@media(max-width:600px)": {
                              width: "100%",
                              wordBreak: "break-all",
                            },
                          }}
                        >
                          {" "}
                          {shipping_address_info?.email}
                        </Typography>
                      </Box>

                      {/* right side */}
                      <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": { width: "100% !important" },
                        }}
                      >
                        <Typography component={"p"} sx={{ fontWeight: 600, width:"48%", "@media(max-width:600px)":{width:'100%'} }}>
                        Order Date :
                        </Typography>
                        <Typography component={"p"} sx={{width:"48%", "@media(max-width:600px)":{width:'100%'}}}>
                          {" "}
                          {console.log(dataById[0]?.created, "order date")}
                          {dataById[0]?.created}
                        </Typography>
                      </Box>
                    </Box>

                    {/* row 6.1 */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        "@media(max-width:600px)": { flexDirection: "column" },
                      }}
                    >
                      {/* left side */}
                      {/* <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": { width: "100% !important" },
                        }}
                      >
                        <Typography component={"p"} sx={{ fontWeight: 600, width:"48%", "@media(max-width:600px)":{width:'100%'} }}>
                          Product Price :
                        </Typography>
                        <Typography component={"p"} sx={{width:"48%", "@media(max-width:600px)":{width:'100%'}}}>
                          {" "}
                          {dataById[0]?.product_total_price + " $"}
                        </Typography>
                      </Box> */}

                      {/* right side */}
                      {/* <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": { width: "100% !important" },
                        }}
                      >
                        <Typography component={"p"} sx={{ fontWeight: 600, width:"48%", "@media(max-width:600px)":{width:'100%'} }}>
                        Shipping Fee :
                        </Typography>
                        <Typography component={"p"} sx={{width:"48%", "@media(max-width:600px)":{width:'100%'}}}>
                          {" "}
                          {dataById[0]?.Shipping_fee + " $"}
                        </Typography>
                      </Box> */}
                    </Box>

                    {/* row 6 */}

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        "@media(max-width:600px)": { flexDirection: "column" },
                      }}
                    >
                      {/* left side */}

                      {/* right side */}

                      {/* <Box
                        sx={{
                          display: "flex",
                          width: "48%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": { width: "100% !important" },
                        }}
                      >
                        <Typography component={"p"} sx={{ fontWeight: 600, width:"48%", "@media(max-width:600px)":{width:'100%'} }}>
                        Total Amount :
                        </Typography>
                        <Typography component={"p"} sx={{width:"48%", "@media(max-width:600px)":{width:'100%'}}}>
                          {" "}
                          {dataById[0]?.total_amount + " $"}
                        </Typography>
                      </Box> */}
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  width: "38%",
                  "@media(max-width:600px)": { width: "100%" },
                }}
              >
                <Box
                  sx={{
                    height: "212px !important",
                    "@media(max-width:1200px)": { height: "261px !important" },
                    // "@media(max-width:1100px)": { height: "242px !important" },
                    "@media(max-width:900px)": { height: "236px !important" },
                  }}
                >
                  <Typography
                    component={"h6"}
                    sx={{
                      fontWeight: 600,
                      paddingBottom: "0.2rem",
                      paddingLeft: "0.4rem",
                    }}
                  >
                    Product List
                  </Typography>
                  <Typography component={"hr"} />
                  <Box
                    sx={{ width: "100%", padding: "1rem 0.4rem 0rem 0.4rem" }}
                  >
                    <OrderTable data={dataById[0]?.items} />
                  </Box>
                </Box>

                <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": {
                            width: "100% !important",
                          },
                        }}
                      >
                        <Typography
                          component={"p"}
                          sx={{
                            fontWeight: 600,
                            width: "48%",
                            "@media(max-width:900px)": { width: "47%" },
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          Shipping Method :
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          {" "}
                          {dataById[0]?.shipping_method}
                        </Typography>
                      </Box>

                <Box
                  sx={{
                    height: "212px",
                  }}
                >
                  <Typography
                    component={"h6"}
                    sx={{
                      fontWeight: 600,
                      paddingBottom: "0.2rem",
                      paddingLeft: "0.6rem",
                    }}
                  >
                    Total Price
                  </Typography>
                  <Typography component={"hr"} />
                  <Box>
                    <Box
                      sx={{
                        paddingTop: "1rem",
                        paddingLeft:'0.2rem'
                        // paddingBottom:'4rem'
                      }}
                    >
                      {/* <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": {
                            width: "100% !important",
                          },
                        }}
                      >
                        <Typography
                          component={"p"}
                          sx={{
                            fontWeight: 600,
                            width: "33%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          Product Name :
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          {" "}
                          {dataById[0]?.items[0]?.product_name}
                        </Typography>
                      </Box> */}



                      <Box>
                        {/* left side */}
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            padding: "0.2rem 0.4rem",
                            "@media(max-width:600px)": {
                              width: "100% !important",
                            },
                          }}
                        >
                          <Typography
                            component={"p"}
                            sx={{
                              fontWeight: 600,
                              width: "33%",
                              "@media(max-width:600px)": { width: "100%" },
                            }}
                          >
                            Product Price :
                          </Typography>
                          <Typography
                            component={"p"}
                            sx={{
                              width: "48%",
                              "@media(max-width:600px)": { width: "100%" },
                            }}
                          >
                            {" "}
                            { " $"+ " " + dataById[0]?.product_total_price}
                          </Typography>
                        </Box>

                        {/* right side */}
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            padding: "0.2rem 0.4rem",
                            "@media(max-width:600px)": {
                              width: "100% !important",
                            },
                          }}
                        >
                          <Typography
                            component={"p"}
                            sx={{
                              fontWeight: 600,
                              width: "42%",
                              "@media(max-width:600px)": { width: "100%" },
                            }}
                          >
                            Shipping Fee :
                          </Typography>
                          <Typography
                            component={"p"}
                            sx={{
                              width: "48%",
                              "@media(max-width:600px)": { width: "100%" },
                            }}
                          >
                            {" "}
                            {" $"+ " " + dataById[0]?.Shipping_fee}
                          </Typography>
                        </Box>
                      </Box>

                      {/* total amount */}

                      <Typography
                        component={"p"}
                        sx={{
                          border: "1px solid purple",
                          marginTop: "1rem",
                        }}
                      />

                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          padding: "0.2rem 0.4rem",
                          "@media(max-width:600px)": {
                            width: "100% !important",
                          },
                        }}
                      >
                        <Typography
                          component={"p"}
                          sx={{
                            fontWeight: 600,
                            width: "42%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          Total Amount :
                        </Typography>
                        <Typography
                          component={"p"}
                          sx={{
                            width: "48%",
                            "@media(max-width:600px)": { width: "100%" },
                          }}
                        >
                          {" "}
                          {" $"+ " " + dataById[0]?.total_amount}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </ModalBody>
      </Modal>
    </>
  );
};

export default OrderView;
