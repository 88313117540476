import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import Leftsiderbar from "../home/allcmp/Leftsidebar";
import MaterialTable from "material-table";
import { API_URL } from "../../../config";
import Top from "../Top";
import "../Products/product.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { Box, TextField, Typography } from "@mui/material";

import axios from "axios";
import SuccessPop from "../SuccessPop/SuccessPop";
import ErrorPop from "../ErrorrPop/ErrorPop";
import DetailPopUp from "../Products/DetailPopUp";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import EditFaqCategroy from "./EditFaqCategroy";
import DeleteFaqCategory from "./DeleteFaqCategory";

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: "black",
    fontSize: "15px",
  },
  bin1: {
    margin: "5px 0 !important",
    padding: "15px",
    width: "100%",
    border: "1px solid #7c13867d !important",
    borderRadius: "8px ",
    background: "transparent",
    color: "#fff",
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&:focus-Visible": {
      outline: "none !important",
      border: "none !important",
    },
    "&:hover": {
      border: "none",
    },
    "& .MuiInputBase-root": {
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },

    "&:focus": {
      boxShadow: "none !important",
      border: "1px solid #7c13867d !important",
      borderRadius: "10px !important ",
    },
    "& input": {
      padding: "10px",
      fontSize: "15px",
      borderRadius: "10px",
      fontWeight: "500",
      "&::placeholder": {
        color: "#7C1386 !important",
        fontSize: "15px",
      },
    },
    "& textarea": {
      fontSize: "16px",
      color: "#7c13867d !important",
      padding: "10px !important",
      fontWeight: "500",
    },
  },
  btns: {
    textAlign: "center",
    margin: "10px 0",
    "& button": {
      fontSize: "15px",
      background: "#7C1386 !important",
      borderRadius: "10px",
      textTransform: "capitalize",
      color: "#fff",
      padding: "10px 30px",
      transition: "0.3s all !important",

      "&.Mui-disabled": {
        cursor: "not-allowed !important",
        pointerEvents: "auto !important",
        color: "rgb(255 255 255 / 38%) !important",
        "&:hover": {
          opacity: "1",
          transform: "scale(1) !important",
        },
      },
    },
  },
  error: {
    color: "#f14668",
    fontSize: "14px !important",
    paddingTop: "3px",
    wordBreak: "break-all",
  },
}));

const FaqCategroy = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [changeExpirydateModal, setChangeExpirydateModal] = useState(false);
  const handleAdd = () => setChangeExpirydateModal(!changeExpirydateModal);
  const [openEdit, setOpenEdit] = useState(false);
  const handleEditOpen = () => setOpenEdit(true);
  const handleEditClose = () => setOpenEdit(false);
  const handleEditPop = () => setOpenEdit(!openEdit);
  const [openSuccess, setOpenSuccess] = useState(false);
  const handleSuccesssPop = () => setOpenSuccess(!openSuccess);
  const [openDelete, setOpenDelete] = useState(false);
  const handleDeletePop = () => setOpenDelete(!openDelete);
  const handleDeleteOpen = () => setOpenDelete(true);

  const [openError, setOpenError] = useState(false);
  const handleErrorPop = () => setOpenError(!openError);
  const [openDetail, setOpenDetail] = useState(false);
  const handleDetail = () => setOpenDetail(!openDetail);

  const [updateData, setUpdateData] = useState([]);
  const [message, setMessage] = useState("");
  const handleMesssage = (value) => setMessage(value);
  const [deleteId, setDeleteId] = useState("");
  const [description, setDescription] = useState("");
  const [filterSelect, setFilterSelect] = useState("0");

  const validationSchema = yup.object({
    category: yup.string().required("Required").trim(),
  });

  const formik = useFormik({
    initialValues: {
      category: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await axios.post(`${API_URL}/create/faqCategory`, {
          name: values.category,
          status: 1,
        });
        if (response.status === 201) {
          const resp = response.data;
          handleMesssage(resp.message);
          getFaqcategory();
          handleAdd();
          handleSuccesssPop();
          resetForm();
        }
      } catch (error) {
        if (error.response.status === 400 || error.response.status === 404) {
          handleMesssage(error.response.data.message);
          handleErrorPop();
        } else {
          console.log(error);
        }
      }
    },
  });

  useEffect(() => {
    getFaqcategory();
  }, [filterSelect]);

  const getFaqcategory = () => {
    fetch(API_URL + "/get/all/faqCategory", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      if (response.status == 200) {
        const resp = await response.json();
        let _temp = [];
        resp.faqscategory.map((v, i) => {
          _temp.push({
            category: v.name,
            action: (
              <p className="action-p">
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip,
                  }}
                  title="Edit"
                  placement="top"
                >
                  <i
                    className="fa fa-edit action-icon"
                    onClick={() => handleEdit(v.id)}
                  ></i>
                </Tooltip>
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip,
                  }}
                  title="Delete"
                  placement="top"
                >
                  <i
                    className="fa fa-trash-o action-icon"
                    onClick={() => handleDelete(v.id)}
                  ></i>
                </Tooltip>
              </p>
            ),
          });
        });
        let revData = _temp.reverse();
        setData(revData);
      } else if (response.status == 401) {
        logout();
      } else {
        alert("network error");
      }
    });
  };

  const handleEdit = (id) => {
    handleEditOpen();
    getFaqCategoryByID(id);
  };

  const getFaqCategoryByID = (id) => {
    fetch(API_URL + `/get/faqCategory/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      if (response.status == 200) {
        const resp = await response.json();
        console.log(resp, "data by id");
        setUpdateData(resp.faqcategory);
      } else if (response.status == 401) {
        logout();
      } else {
        alert("network error");
      }
    });
  };

  const handleDelete = (id) => {
    handleDeleteOpen();
    setDeleteId(id);
  };

  const deleteFaq = () => {
    fetch(API_URL + `/delete/faqCategory/${deleteId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        getFaqcategory();
        handleDeletePop();
        handleMesssage(data.message);
        handleSuccesssPop();
      })
      .catch((error) => {
        console.error("Error during DELETE request:", error);
      });
  };

  const handleAddFaqCategory = () => {
    handleAdd();
  };

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const columns = [
    {
      title: "Categories",
      field: "category",
    },

    {
      title: "Action",
      field: "action",
    },
  ];

  return (
    <div className="">
      <div className="cmail">
        <Top />
      </div>
      <div className="wrp-dashbord">
        <div className="cemail-left">
          <Leftsiderbar />
        </div>
        <div className="right-section">
          <div style={{ padding: "2rem 0rem" }}>
            <div className="onlineaccess-wrp">
              <div className="onlineaccess-child1">
                <h3>FAQ Categories</h3>
              </div>
              <div className="add-btn">
                <button onClick={handleAddFaqCategory}>Add Category</button>
              </div>
            </div>
          </div>

          <div className="wrp-bankform">
            <div className="mobiletable">
              <div style={{ maxWidth: "100%" }}>
                <MaterialTable
                  options={{
                    search: true,
                    showTitle: false,
                    toolbar: true,
                    pageSize: 20,
                    //  pageSize: data?.length <= 5 ? 5 : data?.length > 5 && data?.length <= 10 ? 10 : data?.length > 10 && data?.length <= 20 ? 20 : data?.length > 20 && data?.length <= 30 ? 30 : data?.length > 30 && data?.length <= 40 ? 40 : data?.length > 40 && data?.length <= 50 ? 50 : data?.length > 50 && data?.length <= 100 ? 100 : 150,
                    pageSizeOptions: [5, 10, 20, 50, 100, 150, 200],
                  }}
                  columns={columns}
                  data={data}
                  title=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={changeExpirydateModal}
        toggle={handleAdd}
        className="connect-boxSupport"
        centered={true}
      >
        <ModalHeader toggle={handleAdd}>
          <span className="ml-1 roititle">Add FAQ Category</span>
        </ModalHeader>
        <ModalBody>
          <div className="changedate-box">
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  "@media(max-width:600px)": {
                    flexDirection: "column",
                    // gap:'10px !important'
                  },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    "@media(max-width:600px)": { width: "100% !important" },
                  }}
                >
                  {/* row 1 left side  */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      "@media(max-width:600px)": {
                        flexDirection: "column",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        "@media(max-width:600px)": { width: "100% !important" },
                      }}
                    >
                      <Typography
                        component={"label"}
                        sx={{ fontWeight: "600", color: "#7C1386" }}
                      >
                        Category<sup>*</sup>
                      </Typography>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="category"
                        name="category"
                        type="text"
                        className={classes.bin1}
                        value={formik.values.category}
                        placeholder="Enter category "
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="standard"
                      />
                      {formik.touched.category && formik.errors.category && (
                        <Typography variant="body1" className={classes.error}>
                          {formik.errors.category}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>

                {/* unused code  */}
              </Box>

              <Box className={classes.btns}>
                <Button
                  type="submit"
                  disabled={!(formik.isValid && formik.dirty)}
                >
                  Submit
                </Button>
              </Box>
            </form>
          </div>
        </ModalBody>
      </Modal>
      <EditFaqCategroy
        handleEditPop={handleEditPop}
        openEdit={openEdit}
        updateData={updateData}
        getFaqcategory={getFaqcategory}
        handleMesssage={handleMesssage}
        handleSuccesssPop={handleSuccesssPop}
        handleErrorPop={handleErrorPop}
        handleEditOpen={handleEditOpen}
        handleEditClose={handleEditClose}
      />
      <SuccessPop
        handleSuccesssPop={handleSuccesssPop}
        openSuccess={openSuccess}
        message={message}
      />
      <ErrorPop
        handleErrorPop={handleErrorPop}
        openError={openError}
        message={message}
      />

      <DetailPopUp
        handleErrorPop={handleDetail}
        openError={openDetail}
        message={description}
      />
      <DeleteFaqCategory
        handleSuccesssPop={handleDeletePop}
        openSuccess={openDelete}
        deleteFaq={deleteFaq}
      />
    </div>
  );
};

export default FaqCategroy;
