import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { Box, FormControl, Grid, NativeSelect, TextField, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { API_URL } from "../../../config";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertFromHTML, convertToHTML } from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const useStyles = makeStyles((theme) => ({

  bin1: {
    margin: "0px 0 !important",
    padding: "15px",
    width: "100%",
    border: "1px solid #7c13867d !important",
    borderRadius: "8px ",
    background: "transparent",
    color: "#fff",
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&:focus-Visible": {
      outline: "none !important",
      border: "none !important",
    },
    "&:hover": {
      border: "none",
    },
    "& .MuiInputBase-root": {
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },

    "&:focus": {
      boxShadow: "none !important",
      border: "1px solid #7c13867d !important",
      borderRadius: "10px !important ",
    },
    "& input": {
      padding: "10px",
      fontSize: "15px",
      borderRadius: "10px",
      fontWeight: "500",
      "&::placeholder": {
        color: "#7C1386 !important",
        fontSize: "15px",
      },
    },
    "& textarea": {
      fontSize: "16px",
      color: "#7c13867d !important",
      padding: "10px !important",
      fontWeight: "500",
    },
  },
  btns: {
    textAlign: "center",
    margin: "10px 0",
    "& button": {
      fontSize: "15px",
      background: "#7C1386 !important",
      borderRadius: "10px",
      textTransform: "capitalize",
      color: "#fff",
      padding: "10px 30px",
      transition: "0.3s all !important",

      "&.Mui-disabled": {
        cursor: "not-allowed !important",
        pointerEvents: "auto !important",
        color: "rgb(255 255 255 / 38%) !important",
        "&:hover": {
          opacity: "1",
          transform: "scale(1) !important",
        },
      },
    },
  },
  error: {
    color: "#f14668",
    fontSize: "14px !important",
    // paddingTop: "3px",
    wordBreak: "break-all",
  },
}));

const EditAffiliate = ({
  openEdit,
  handleEditPop,
  updateData,
  categroyData,
  getFaq,
  handleMesssage,
  handleSuccesssPop,
  handleErrorPop,
}) => {
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);




  const classes = useStyles();
  useEffect(() => {
 
    formik.setValues({
      firstName: updateData[0]?.firstName || "",
      lastName: updateData[0]?.lastName || "",
      email: updateData[0]?.email || "",
      username: updateData[0]?.username || "",
      city: updateData[0]?.city || "",
      phone: updateData[0]?.phone || "",
      business: updateData[0]?.business || "",
      state: updateData[0]?.state || "",
      country: updateData[0]?.country || "",
      comission: updateData[0]?.comission || "",
      status: updateData[0]?.status || "",
});
  }, [updateData]);





  const validationSchema = yup.object({
    firstName: yup.string().required("First name is required").trim(),
    lastName: yup.string().required("Last name is required").trim(),
    username: yup.string().required("Username is required").trim(),
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required")
      .trim(),
    business: yup.string().trim(),
    city: yup.string().trim(),
    state: yup.string().trim(),
    country: yup.string().trim(),
    phone: yup.string().trim(),
    status: yup.string().trim(),
    comission: yup.string().required("Required").trim(),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      business: "",
      city: "",
      state: "",
      country: "",
      phone: "",
      status: "",
      comission:""
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm, errors }) => {
      // console.log("hello");
      // if (errors) {
      //   setErrors(errors);
      // }
        handlUpdateAffiliate(values);
  
    },
  });

  const getCountryList = (id) => {
    fetch(API_URL + `/countries`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      if (response.status == 200) {
        const resp = await response.json();
     setCountryList(resp.countries)
      } else {
        console.log("network error");
      }
    });
  };
  const getStateList = (id) => {
    fetch(API_URL + `/states?country_id=${formik.values.country}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      if (response.status == 200) {
        const resp = await response.json();
        setStateList(resp.states)
      } else {
        console.log("network error");
      }
    });
  };
useEffect(()=>{
  getCountryList()
},[])
useEffect(()=>{
  getStateList()
},[formik.values.country])
  const handlUpdateAffiliate = async (value) => {
 
    try {
      const response = await axios.post(
        `${API_URL}/update/affiliate/${updateData[0]?.id}`,
        value
      );
      if (response.status == 200) {
        const resp = response.data;
        handleMesssage("Affiliate Updated Successfully..");
        getFaq(0);
        handleEditPop();
        handleSuccesssPop();
      }
    } catch (error) {
      if (error.response.status === 400 || error.response.status === 404) {
        handleMesssage(error.response.data.message);
        handleErrorPop();
      } else {
        console.log("Network error");
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={openEdit}
        toggle={handleEditPop}
        className="connect-boxSupport"
        centered={true}
        style={{maxWidth:"600px"}}
      >
        <ModalHeader toggle={handleEditPop}>
          <span className="ml-1 roititle">Edit Affiliate</span>
        </ModalHeader>
        <ModalBody>
          <div className="changedate-box">
            <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography
                        component={"label"}
                        sx={{ fontWeight: "600", color: "#7C1386" }}
                      >
                        First Name<sup>*</sup>
                      </Typography>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="firstName"
                        name="firstName"
                        type="text"
                        className={classes.bin1}
                        value={formik.values.firstName}
                        placeholder="Enter First Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="standard"
                      />
                      {formik.touched.firstName && formik.errors.firstName && (
                        <Typography variant="body1" className={classes.error}>
                          {formik.errors.firstName}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography
                        component={"label"}
                        sx={{ fontWeight: "600", color: "#7C1386" }}
                      >
                        Last Name<sup>*</sup>
                      </Typography>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="lastName"
                        name="lastName"
                        type="text"
                        className={classes.bin1}
                        value={formik.values.lastName}
                        placeholder="Enter Last Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="standard"
                      />
                      {formik.touched.lastName && formik.errors.lastName && (
                        <Typography variant="body1" className={classes.error}>
                          {formik.errors.lastName}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography
                        component={"label"}
                        sx={{ fontWeight: "600", color: "#7C1386" }}
                      >
                        Username<sup>*</sup>
                      </Typography>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="username"
                        name="username"
                        type="text"
                        className={classes.bin1}
                        value={formik.values.username}
                        placeholder="Enter Username"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="standard"
                      />
                      {formik.touched.username && formik.errors.username && (
                        <Typography variant="body1" className={classes.error}>
                          {formik.errors.username}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography
                        component={"label"}
                        sx={{ fontWeight: "600", color: "#7C1386" }}
                      >
                        Email<sup>*</sup>
                      </Typography>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="email"
                        name="email"
                        type="text"
                        className={classes.bin1}
                        value={formik.values.email}
                        placeholder="Enter Email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="standard"
                      />
                      {formik.touched.email && formik.errors.email && (
                        <Typography variant="body1" className={classes.error}>
                          {formik.errors.email}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography
                        component={"label"}
                        sx={{ fontWeight: "600", color: "#7C1386" }}
                      >
                        Commission (in %)<sup>*</sup>
                      </Typography>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="comission"
                        name="comission"
                        type="number"
                        className={classes.bin1}
                        value={formik.values.comission}
                        placeholder="Enter Commission"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="standard"
                      />
                      {formik.touched.comission && formik.errors.comission && (
                        <Typography variant="body1" className={classes.error}>
                          {formik.errors.comission}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography
                        component={"label"}
                        sx={{ fontWeight: "600", color: "#7C1386" }}
                      >
                        Business Name
                      </Typography>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="business"
                        name="business"
                        type="text"
                        className={classes.bin1}
                        value={formik.values.business}
                        placeholder="Enter Business Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="standard"
                      />
                      {formik.touched.business && formik.errors.business && (
                        <Typography variant="body1" className={classes.error}>
                          {formik.errors.business}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography
                        component={"label"}
                        sx={{ fontWeight: "600", color: "#7C1386" }}
                      >
                        City
                      </Typography>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="city"
                        name="city"
                        type="text"
                        className={classes.bin1}
                        value={formik.values.city}
                        placeholder="Enter City"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="standard"
                      />
                      {formik.touched.city && formik.errors.city && (
                        <Typography variant="body1" className={classes.error}>
                          {formik.errors.city}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                  <Typography
                    component={"label"}
                    sx={{ fontWeight: "600", color: "#7C1386" }}
                  >
                    Country
                  </Typography>
                  <FormControl
                    fullWidth
                    sx={{
                      border: "1px solid #7c13867d",
                      mr: "0",
                      borderRadius: "5px ",
                      "& .MuiInputBase-root::before": {
                        borderBottom: "0 !important",
                      },
                      "& .MuiInput-root:hover::before": {
                        borderBottom: "0",
                      },
                      "& .MuiInputBase-root::after": {
                        borderBottom: "0",
                      },
                      "& select": {
                        p: "10px",
                      },
                      "& option": {
                        padding: "5px !important",
                      },
                    }}
                  >
                    <NativeSelect
                      name="country"
                      id="Country"
                      value={formik.values.country}
                      onChange={formik.handleChange}
                    >
                     <option value="">--Select Country--</option>
                      {
                        countryList?.map((e,i)=>(
                          <option key={i} value={e?.id}>{e?.name}</option>
                        ))
                      }
                    </NativeSelect>
                  </FormControl>
                  {formik.touched.country && formik.errors.country && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.country}
                    </Typography>
                  )}
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography
                    component={"label"}
                    sx={{ fontWeight: "600", color: "#7C1386" }}
                  >
                    State/Province/Others
                  </Typography>
                  <FormControl
                    fullWidth
                    sx={{
                      border: "1px solid #7c13867d",
                      mr: "0",
                      borderRadius: "5px ",
                      "& .MuiInputBase-root::before": {
                        borderBottom: "0 !important",
                      },
                      "& .MuiInput-root:hover::before": {
                        borderBottom: "0",
                      },
                      "& .MuiInputBase-root::after": {
                        borderBottom: "0",
                      },
                      "& select": {
                        p: "10px",
                      },
                      "& option": {
                        padding: "5px !important",
                      },
                    }}
                  >
                    <NativeSelect
                      name="state"
                      id="State"
                      value={formik.values.state}
                      onChange={formik.handleChange}
                    >
                     <option value="">--Select State--</option>
                      {
                        stateList?.map((e,i)=>(
                          <option key={i} value={e?.id}>{e?.name}</option>
                        ))
                      }
                    </NativeSelect>
                  </FormControl>
                  {formik.touched.state && formik.errors.state && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.state}
                    </Typography>
                  )}
                </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography
                        component={"label"}
                        sx={{ fontWeight: "600", color: "#7C1386" }}
                      >
                        Phone No.
                      </Typography>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="phone"
                        name="phone"
                        type="text"
                        className={classes.bin1}
                        value={formik.values.phone}
                        placeholder="Enter Phone Number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="standard"
                      />
                      {formik.touched.phone && formik.errors.phone && (
                        <Typography variant="body1" className={classes.error}>
                          {formik.errors.phone}
                        </Typography>
                      )}
                    </Grid>
                    
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography
                        component={"label"}
                        sx={{ fontWeight: "600", color: "#7C1386" }}
                      >
                        Status
                      </Typography>
                      <FormControl
                        fullWidth
                        sx={{
                          border: "1px solid #7c13867d",
                          mr: "0",
                          borderRadius: "5px ",
                          "& .MuiInputBase-root::before": {
                            borderBottom: "0 !important",
                          },
                          "& .MuiInput-root:hover::before": {
                            borderBottom: "0",
                          },
                          "& .MuiInputBase-root::after": {
                            borderBottom: "0",
                          },
                          "& select": {
                            p: "10px",
                          },
                          "& option": {
                            padding: "5px !important",
                          },
                        }}
                      >
                        <NativeSelect name="status" id="status" value={formik.values.status} onChange={formik.handleChange}>
                          <option value={""}>--Select--</option>
                          <option value={"1"}>Active</option>
                          <option value={"2"}>Inactive</option>
                       
                        </NativeSelect>
                      </FormControl>
                      {formik.touched.status && formik.errors.status && (
                        <Typography variant="body1" className={classes.error}>
                          {formik.errors.status}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
              <Box className={classes.btns} mt={"1.5rem"}>
                <Button
                   type="submit"
                  // disabled={!(formik.isValid)}
                >
                  Update
                </Button>
              </Box>
            </form>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EditAffiliate;
