import { Typography } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import React, { useState } from "react";

const OrderTable = ({data}) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    let tableData = []

    data?.map((item, i)=>{
      tableData.push({
        product_id:item.product_id,
        product_image:item.product_image,
        product_name:item.product_name,
        quantity:item.quantity,
        unit_price:"$ " + item.unit_price
      })
    })


  
    const columns = [
      { id: 'product_name', label: 'Product Name', minWidth: 150 },
      { id: 'quantity', label: 'Quantity', minWidth: 100 },
      { id: 'unit_price', label: 'Unit Price', minWidth: 100 },
    ];
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 5));
      setPage(0); // Reset to the first page when changing rows per page
    };

  return <>
                    {/* <Typography component={'table'} sx={{width:'100%'}}>
                  <Typography component={'thead'}>
                    <Typography component={'tr'}>
                      <Typography component={'th'} sx={{padding:'0.2rem 0.6rem', fontWeight:600, border:"1px solid black"}}> Product Name</Typography>
                      <Typography component={'th'} sx={{padding:'0.2rem 0.6rem', fontWeight:600, border:"1px solid black"}}> Quantity</Typography>
                      <Typography component={'th'} sx={{padding:'0.2rem 0.6rem', fontWeight:600, border:"1px solid black"}}> Unit Price</Typography>
                    </Typography>
                  </Typography>
                  <Typography component={'tbody'}>
                 { data?.map((item, i)=>(
                 <Typography component={'tr'}>
                    <Typography component={'td'} sx={{padding:'0.2rem 0.6rem', fontWeight:500, border:"1px solid black"}}>{item?.product_name}</Typography>
                    <Typography component={'td'} sx={{padding:'0.2rem 0.6rem', fontWeight:500, border:"1px solid black"}}>{item?.quantity}</Typography>
                    <Typography component={'td'} sx={{padding:'0.2rem 0.6rem', fontWeight:500, border:"1px solid black"}}>{item?.unit_price + " $"}</Typography>
                  </Typography>
                 ))
                  }
                  </Typography>
                </Typography> */}

                <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData?.length !=0 && tableData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.product_id}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number'
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

  </>;
};

export default OrderTable;
