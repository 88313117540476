import { Typography } from "@mui/material";
import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const SuccessPop = ({ openSuccess, handleSuccesssPop, message }) => {
  return (
    <>
      <Modal
        isOpen={openSuccess}
        toggle={handleSuccesssPop}
        // className="connect-box"
        centered={true}
        style={{ maxWidth: "500px !important" }}
      >
        <ModalHeader toggle={handleSuccesssPop}>
          <span className="ml-1 roititle">Success</span>
        </ModalHeader>
        <ModalBody>
          <div className="changedate-box">
            <Typography
              component={"p"}
              sx={{ textAlign: "center", padding: "1rem" }}
            >
              {message}
            </Typography>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default SuccessPop;
