import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Row } from "reactstrap";
import { Box, Button } from "@mui/material";
import ImageView from "../ImageView/ImageView";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { API_URL } from "../../../config";
import SuccessPop from "../SuccessPop/SuccessPop";
import ErrorPop from "../ErrorrPop/ErrorPop";

const useStyles = makeStyles((theme) => ({
    error: {
      color: "#f14668",
      fontSize: "14px !important",
      paddingTop: "3px",
      wordBreak: "break-all",
    },
    btns: {
        textAlign: "center",
        margin: "10px 0",
        "& button": {
          fontSize: "15px",
          background: "#7C1386 !important",
          borderRadius: "10px",
          textTransform: "capitalize",
          color: "#fff !important",
          padding: "10px 30px",
          transition: "0.3s all !important",
    
          "&.Mui-disabled": {
            cursor: "not-allowed !important",
            pointerEvents: "auto !important",
            color: "rgb(255 255 255 / 38%) !important",
            "&:hover": {
              opacity: "1",
              transform: "scale(1) !important",
            },
          },
        },
      },
  }));

const AddImagePop = ({ openError, handleErrorPop, imageId, getProduct }) => {
    const classes = useStyles();

    const [productImage, setProductImage] = useState();
    const [isValidImageSize, setIsValidImageSize] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const handleSuccesssPop = () => setOpenSuccess(!openSuccess);
    const [message, setMessage] = useState("")
    const [openImageError, setOpenImageError] = useState(false);
    const handleImageErrorPop = () => setOpenImageError(!openError);

    const handleProductImage = (event) =>{
        setIsValidImageSize(false);
        let imageFile = event.target.files[0];

        if (imageFile.size <= 5 * 1024 * 1024) {
            setProductImage(imageFile)
          } else {
            setIsValidImageSize(true);
          }
    }

    const handleAddImage = async()=> {
        if(productImage){
            const formData = new FormData();
            formData.append("product_id", imageId);
            formData.append("form", productImage);
            try {
                const response = await axios.post(`${API_URL}/upload/product/image`, formData);
      
                if (response.status === 200) {
                  const resp = response.data;
                  setMessage(resp.message)
                  getProduct();
                  handleErrorPop()
                  handleSuccesssPop();
                  setProductImage("");
                }
              } catch (error) {
                if (error.response.status === 400 || error.response.status === 404) {
                    handleImageErrorPop()
                    setMessage(error.response.data.message)
                } else {
                  throw new Error("Network error");
                }
              }
        }else{
            return false
        }

        // alert("function work" + imageId)
    }
  return (
    <>
      <Modal
        isOpen={openError}
        toggle={handleErrorPop}
        // className="connect-box"
        centered={true}
        style={{ maxWidth: "500px !important" }}
      >
        <ModalHeader toggle={handleErrorPop}>
          <span className="ml-1 roititle">Add Product Images</span>
        </ModalHeader>
        <ModalBody>
        <Box
                sx={{
                  padding: "0.4rem 0rem",
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <Typography
                  component={"label"}
                  sx={{ fontWeight: "600", color: "#7C1386" }}
                >
                  Product Image<sup>*</sup>
                </Typography>
                <input
                  type="file"
                  accept="image/*"
                  required
                  onChange={handleProductImage}
                  style={{
                    width: "100%",
                    border: "1px solid purple",
                    padding: "0.5rem",
                    borderRadius: "8px",
                  }}
                />
                {isValidImageSize === true ? (
                  <Typography component={"p"} className={classes.error}>
                    File size should be 5MB or less.
                  </Typography>
                ):''}
                {/* {multipleImageName &&
                  multipleImageName?.map((item, i) => (
                    <>
                      {item?.name != undefined && (
                        <Typography
                          component={"p"}
                          sx={{
                            width: "fit-content",
                            overflow: "auto",
                            padding: "0.2rem 0.3rem",
                            borderRadius: "0.5rem",
                            border: "1px solid purple",
                          }}
                        >
                          <Typography component={"span"}>
                            {item?.name}
                          </Typography>{" "}
                          <CancelIcon
                            sx={{ color: "black" }}
                            onClick={() => handleImageDelete(item?.name)}
                          />
                        </Typography>
                      )}
                    </>
                  ))} */}
              </Box>
              <Box className={classes.btns}>
                <Button
                  type="submit"
                  onClick={()=>handleAddImage()}
                >
                  Submit
                </Button>
              </Box>
        </ModalBody>
      </Modal>
      <SuccessPop
        handleSuccesssPop={handleSuccesssPop}
        openSuccess={openSuccess}
        message={message}
      />
            <ErrorPop
        handleErrorPop={handleImageErrorPop}
        openError={openImageError}
        message={message}
      />
    </>
  );
};

export default AddImagePop;
