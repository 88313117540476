import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import Leftsiderbar from "../home/allcmp/Leftsidebar";
import MaterialTable from "material-table";
import { API_URL } from "../../../config";
import Top from "../Top";
import { useFormik } from "formik";
import * as yup from "yup";
import { Box, TextField, Typography } from "@mui/material";

import axios from "axios";
import SuccessPop from "../SuccessPop/SuccessPop";
import DeleteProduct from "../Products/DeleteProduct";
import EditCategroy from "./EditCategroy";

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: "black",
    fontSize: "15px",
  },
  bin1: {
    margin: "5px 0 !important",
    padding: "15px",
    width: "100%",
    border: "1px solid #7c13867d !important",
    borderRadius: "8px ",
    background: "transparent",
    color: "#fff",
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&:focus-Visible": {
      outline: "none !important",
      border: "none !important",
    },
    "&:hover": {
      border: "none",
    },
    "& .MuiInputBase-root": {
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },

    "&:focus": {
      boxShadow: "none !important",
      border: "1px solid #7c13867d !important",
      borderRadius: "10px !important ",
    },
    "& input": {
      padding: "10px",
      fontSize: "15px",
      borderRadius: "10px",
      fontWeight: "500",
      "&::placeholder": {
        color: "#7C1386 !important",
        fontSize: "15px",
      },
    },
    "& textarea": {
      fontSize: "16px",
      color: "#7c13867d !important",
      padding: "10px !important",
      fontWeight: "500",
    },
  },
  btns: {
    textAlign: "center",
    margin: "10px 0",
    "& button": {
      fontSize: "15px",
      background: "#7C1386 !important",
      borderRadius: "10px",
      textTransform: "capitalize",
      color: "#fff",
      padding: "10px 30px",
      transition: "0.3s all !important",

      "&.Mui-disabled": {
        cursor: "not-allowed !important",
        pointerEvents: "auto !important",
        color: "rgb(255 255 255 / 38%) !important",
        "&:hover": {
          opacity: "1",
          transform: "scale(1) !important",
        },
      },
    },
  },
  error: {
    color: "#f14668",
    fontSize: "14px !important",
    paddingTop: "3px",
    wordBreak: "break-all",
  },
}));

const Categroy = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [changeExpirydateModal, setChangeExpirydateModal] = useState(false);
  const handleAdd = () => setChangeExpirydateModal(!changeExpirydateModal);
  const [openEdit, setOpenEdit] = useState(false);
  const handleEditPop = () => setOpenEdit(!openEdit);
  const handleEditOpen = () => setOpenEdit(true);
  const [openSuccess, setOpenSuccess] = useState(false);
  const handleSuccesssPop = () => setOpenSuccess(!openSuccess);
  const [openDelete, setOpenDelete] = useState(false);
  const handleDeleteOpen = () => setOpenDelete(true);
  const handleDeletePop = () => setOpenDelete(!openDelete);
  const [openError, setOpenError] = useState(false);
  const handleErrorPop = () => {
    setOpenError(!openError);
  };
  const [productImage, setProductImage] = useState("");
  const [selectCategroy, setSelectCategroy] = useState("Select Categroy");
  const [categroyData, setCategroyData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [message, setMessage] = useState("");
  const handleMesssage = (value) => setMessage(value);
  const [deleteId, setDeleteId] = useState("");
  const [categroyId, setCategroyId] = useState();
  const validationSchema = yup.object({
    category_name: yup.string().required("Required").trim(),
    description: yup.string().required("Required").trim(),
  });
  const formik = useFormik({
    initialValues: {
      category_name: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await axios.post(
          `${API_URL}/add/product/category`,
          values
        );

        if (response.status === 200) {
          const resp = response.data;
          handleMesssage(resp.message);
          getCategroy();
          handleAdd();
          handleSuccesssPop();
          resetForm();
        }
      } catch (error) {
        if (error.response.status === 400 || error.response.status === 404) {
          handleMesssage(error.response.data.message);
          handleErrorPop();
        } else {
          throw new Error("Network error");
        }
      }
    },
  });

  useEffect(() => {
    getCategroy();
  }, []);

  const getCategroy = () => {
    fetch(API_URL + "/get/all/product/category", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      if (response.status == 200) {
        const resp = await response.json();

        let _temp = [];
        resp.data.map((v, i) => {
          _temp.push({
            Name: v.category_name,
            description: v.description,
            action: (
              <p className="action-p">
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip,
                  }}
                  title="Edit"
                  placement="top"
                >
                  <i
                    className="fa fa-edit action-icon"
                    onClick={() => handleEdit(v.id)}
                  ></i>
                </Tooltip>
                {/* <Tooltip
                  classes={{
                    tooltip: classes.customTooltip,
                  }}
                  title="Delete"
                  placement="top"
                >
                  <i
                    className="fa fa-trash-o action-icon"
                    onClick={() => handleDelete(v.id)}
                  ></i>
                </Tooltip> */}
              </p>
            ),
          });
        });
        setData(_temp);
      } else if (response.status == 401) {
        logout();
      } else {
        alert("network error");
      }
    });
  };

  const handleEdit = (id) => {
    handleEditOpen();
    setCategroyId(id);
    getCategroyByID(id);
  };

  const getCategroyByID = (id) => {
    fetch(API_URL + `/get/product/category/by/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      if (response.status == 200) {
        const resp = await response.json();
        setUpdateData(resp.data);
      } else if (response.status == 401) {
        logout();
      } else {
        alert("network error");
      }
    });
  };

  const handleDelete = (id) => {
    handleDeleteOpen();
    setDeleteId(id);
  };

  const deleteCategroy = () => {
    fetch(API_URL + `/delete/product/category/by/${deleteId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        getCategroy();
        handleDeletePop();
        handleMesssage(data.message);
        handleSuccesssPop();
      })
      .catch((error) => {
        console.error("Error during DELETE request:", error);
      });
  };

  const handleAddProduct = () => {
    handleAdd();
  };

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const columns = [
    {
      title: "Category Name",
      field: "Name",
    },
    {
      title: "Description",
      field: "description",
    },
    {
      title: "Action",
      field: "action",
    },
  ];

  return (
    <div className="">
      <div className="cmail">
        <Top />
      </div>
      <div className="wrp-dashbord">
        <div className="cemail-left">
          <Leftsiderbar />
        </div>
        <div className="right-section">
          <div className="onlineaccess-wrp">
            <div className="onlineaccess-child1">
              <h3>Categories</h3>
            </div>
            <div className="add-btn">
              <button onClick={handleAddProduct}>Add category</button>
            </div>
          </div>

          <div className="wrp-bankform" style={{marginTop:"1rem"}}>
            <div className="mobiletable">
              <div style={{ maxWidth: "100%" }}>
                <MaterialTable
                  options={{
                    search: true,
                    showTitle: false,
                    toolbar: true,
                    pageSize: 10,
                  }}
                  columns={columns}
                  data={data}
                  title=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={changeExpirydateModal}
        toggle={handleAdd}
        // className="connect-box"
        centered={true}
        style={{ maxWidth: "500px !important" }}
      >
        <ModalHeader toggle={handleAdd}>
          <span className="ml-1 roititle">Add Category</span>
        </ModalHeader>
        <ModalBody>
          <div className="changedate-box">
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  gap: 5,
                  "@media(max-width:600px)": {
                    flexDirection: "column",
                    gap: 0,
                  },
                }}
              >
                <Box
                  sx={{
                    width: "48%",
                    "@media(max-width:600px)": { width: "100% !important" },
                  }}
                >
                  <Typography
                    component={"label"}
                    sx={{ fontWeight: "600", color: "#7C1386" }}
                  >
                    Name<sup>*</sup>
                  </Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="category_name"
                    name="category_name"
                    type="text"
                    className={classes.bin1}
                    value={formik.values.category_name}
                    placeholder="Enter category name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="standard"
                  />
                  {formik.touched.category_name &&
                    formik.errors.category_name && (
                      <Typography variant="body1" className={classes.error}>
                        {formik.errors.category_name}
                      </Typography>
                    )}
                </Box>
                <Box
                  sx={{
                    width: "48%",
                    "@media(max-width:600px)": { width: "100% !important" },
                  }}
                >
                  <Typography
                    component={"label"}
                    sx={{ fontWeight: "600", color: "#7C1386" }}
                  >
                    Description<sup>*</sup>
                  </Typography>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="description"
                    name="description"
                    type="text"
                    className={classes.bin1}
                    value={formik.values.description}
                    placeholder="Enter category description"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="standard"
                  />
                  {formik.touched.description && formik.errors.description && (
                    <Typography variant="body1" className={classes.error}>
                      {formik.errors.description}
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box className={classes.btns}>
                <Button
                  type="submit"
                  disabled={!(formik.isValid && formik.dirty)}
                >
                  Submit
                </Button>
              </Box>
            </form>
          </div>
        </ModalBody>
      </Modal>
      <EditCategroy
        handleEditPop={handleEditPop}
        openEdit={openEdit}
        updateData={updateData}
        categroyData={categroyData}
        getProduct={getCategroy}
        handleMesssage={handleMesssage}
        handleSuccesssPop={handleSuccesssPop}
        handleErrorPop={handleErrorPop}
      />
      <SuccessPop
        handleSuccesssPop={handleSuccesssPop}
        openSuccess={openSuccess}
        message={message}
      />
      <DeleteProduct
        handleSuccesssPop={handleDeletePop}
        openSuccess={openDelete}
        deleteProduct={deleteCategroy}
      />
    </div>
  );
};

export default Categroy;
