import React from "react";


class Editdevice extends React.Component {
    render() {
        return (
            <div>
                <div className='.d-right'>
                    <div className="Editdevicecmp-main-wrp">
                        <div className='Editdevicecmp-wrp'>
                            <a href="/add_new_device">New Devices</a>
                            <a href="/edit_device2">Edit Devices</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Editdevice;