import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import Leftsiderbar from "../home/allcmp/Leftsidebar";
import MaterialTable from "material-table";
import { API_URL } from "../../../config";
import Top from "../Top";
import OrderView from "./OrderView";

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: "black",
    fontSize: "15px",
  },
  error: {
    color: "#f14668",
    fontSize: "14px !important",
    paddingTop: "3px",
    wordBreak: "break-all",
  },
}));

const Orders = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [dataById, setDataById] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const handleEditPop = () => setOpenEdit(!openEdit);
  const handleEditOpen = () => setOpenEdit(true);

  useEffect(() => {
    getOrders();
  }, []);
  const columns = [
    {
      title: "Order Id",
      field: "order_id",
    },
    // {
    //   title: "Quantity",
    //   field: "quantity",
    // },
    {
      title: "Customer Name",
      field: "name",
    },
    {
      title: "Customer Email",
      field: "email",
    },
    {
      title: "Order Date",
      // title: "Date of Delivery",
      field: "created",
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center", // Align the header to the center
        width:'130px'
      },
    },
    {
      title: "Order Status",
      field: "order_status",
    },
    {
      title: "Total Amount",
      field: "total_amount",
    },
    {
      title: "Action",
      field: "action",
    },
  ];

  const getOrders = () => {
    fetch(API_URL + "/get/all-order", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      if (response.status == 200) {
        const resp = await response.json();
        
        // debugger
        let _temp = [];
        resp?.items?.map((v, i) => {
          let bill = JSON.parse(v.billing_address_info)?.fname;
          _temp.push({
            order_id: v.order_id,
            created:convertDateString(v.created),
            name:
              JSON.parse(v.shipping_address_info)?.fname +
              " " +
              JSON.parse(v.shipping_address_info)?.lname,
            email: JSON.parse(v.shipping_address_info)?.email,
            quantity: v.quantity,
            delivery_date: v.delivery_date,
            order_status:
              v.order_status == "" || null
                ? "Incomplete"
                : v.order_status + "ED",
            total_amount:"$" + " " +  v.total_amount,
            action: (
              <p className="action-p">
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip,
                  }}
                  title="View"
                  placement="top"
                >
                  <i
                    className={"fa fa-eye action-icon"}
                    onClick={() => handleView(v.order_id)}
                  ></i>
                </Tooltip>
              </p>
            ),
          });
        });
        setData(_temp);
      } else if (response.status == 401) {
        logout();
      } else {
        alert("network error");
      }
    });
  };

  const getOrdersById = (id) => {
    fetch(API_URL + `/get/order/by/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      if (response.status == 200) {
        const resp = await response.json();
        // debugger
        let _temp = [];
        resp.items.map((item, i) => {
          _temp.push({
            shipping_address_info: JSON.parse(item.shipping_address_info),
            billing_address_info: JSON.parse(item.billing_address_info),
            total_amount: item.total_amount,
            product_total_price: item.product_total_price,
            Shipping_fee: item.Shipping_fee,
            items: item.items,
            shipping_method:item.shipping_method,
            created:convertDateString(item.created)
            
          });
        });
        setDataById(_temp);
      } else if (response.status == 401) {
        logout();
      } else {
        alert("network error");
      }
    });
  };
  
  const convertDateString = (inputString) =>{
    const date = new Date(inputString);

    const year = date.getUTCFullYear().toString(); // Get last two digits of the year
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1 and pad with 0 if needed
    const day = String(date.getUTCDate()).padStart(2, '0'); // Pad day with 0 if needed
    
    return `${year}-${month}-${day}` 
  }

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const handleView = (value) => {
    handleEditOpen();
    getOrdersById(value);
  };

  return (
    <div className="">
      <div className="cmail">
        <Top />
      </div>
      <div className="wrp-dashbord">
        <div className="cemail-left">
          <Leftsiderbar />
        </div>
        <div className="right-section">
          <div className="onlineaccess-wrp">
            <div className="onlineaccess-child1">
              <h3>Orders</h3>
            </div>
            {/* <div className="add-btn">
              <button onClick={handleAddProduct}>Add Product</button>
            </div> */}
          </div>

          <div className="wrp-bankform" style={{marginTop:"1rem"}}>
            <div className="mobiletable">
              <div style={{ maxWidth: "100%" }}>
                <MaterialTable
                  options={{
                    search: true,
                    showTitle: false,
                    toolbar: true,
                    pageSize: 10,
                  }}
                  columns={columns}
                  data={data}
                  title=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <OrderView
        handleEditPop={handleEditPop}
        openEdit={openEdit}
        dataById={dataById}
      />
    </div>
  );
};

export default Orders;
