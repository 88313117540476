import React, { useEffect, useState } from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import { Avatar, Box, Fab, makeStyles } from "@material-ui/core";
import arrayMove from "array-move";
import DeleteIcon from "@mui/icons-material/Delete";
import { API_URL, PRODUCT_S3 } from "../../../config";
import { Button } from "reactstrap";
import SuccessPop from "../SuccessPop/SuccessPop";
import DeleteProduct from "../Products/DeleteProduct";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexWrap: "wrap",
    userSelect: "none",
  },
  item: {
    position: "relative",
    flexShrink: 0,
    display: "flex",
    margin: 8,
    cursor: "grab",
    userSelect: "none",
    borderRadius: "100%",
  },
  image: {
    width: 100,
    height: 100,
    pointerEvents: "none",
    borderRadius: "0.5rem",
  },
  button: {
    position: "absolute",
    top: 0,
    right: 0,
    outline: 0,
    "&:focus": {
      outline: 0,
    },
  },
  dragged: {
    boxShadow:
      "0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)",
    "& button": {
      opacity: 0,
    },
  },

  btns: {
    textAlign: "center",
    margin: "10px 0",
    "& button": {
      fontSize: "15px",
      background: "#7C1386 !important",
      borderRadius: "10px",
      textTransform: "capitalize",
      color: "#fff",
      padding: "10px 30px",
      transition: "0.3s all !important",

      "&.Mui-disabled": {
        cursor: "not-allowed !important",
        pointerEvents: "auto !important",
        color: "rgb(255 255 255 / 38%) !important",
        "&:hover": {
          opacity: "1",
          transform: "scale(1) !important",
        },
      },
    },
  },
});

const ImageView = ({ imageId, getProduct }) => {
  const classes = useStyles();

  const [items, setItems] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const handleSuccesssPop = () => setOpenSuccess(!openSuccess);
  const [message, setMessage] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const handleDeletePop = () => setOpenDelete(!openDelete);
  const handleDeleteOpen = () => setOpenDelete(true);
  const [deleteId, setDeleteId] = useState();

  useEffect(() => {
    getAllProductImages();
  }, []);

  const getAllProductImages = () => {
    fetch(API_URL + `/get/product/images/by/${imageId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      if (response.status == 200) {
        const resp = await response.json();
        let _temp = [];
        resp?.data?.map((item, i) => {
          _temp.push({
            id: item.id,
            image: PRODUCT_S3 + "/" + item.image,
          });
        });
        setItems(_temp);
      } else if (response.status == 401) {
        logout();
      } else {
        alert("network error");
      }
    });
  };


  











  const onSortEnd = (oldIndex, newIndex) => {
    setItems((array) => arrayMove(array, oldIndex, newIndex));
  }

  const ImageDelete = (id) => {
    handleDeleteOpen();
    setDeleteId(id);
  };

  const handleDelete = () => {
    fetch(API_URL + `/delete/product/image/by/${deleteId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        handleDeletePop();
        getAllProductImages();
        handleSuccesssPop();
        setMessage(data.message);
      })
      .catch((error) => {
        console.error("Error during DELETE request:", error);
      });
  };

  const handleChangeOrder = () => {
    let ids = items.map((item) => item.id);

    fetch(API_URL + `/update/product/images/order/by/${imageId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ image_ids: ids }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.status === true) {
          setMessage(data.message);
          getProduct();
          handleSuccesssPop();
          getAllProductImages();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <SortableList
        onSortEnd={onSortEnd}
        className={classes.root}
        draggedItemClassName={classes.dragged}
      >
        {items.map(({ name, image, id }) => (
          <SortableItem key={name}>
            <div className={classes.item}>
              <Avatar 
                className={classes.image}
                alt={name}
                src={image}
                imgProps={{ draggable: false }}
              />
              <Fab
                size="small"
                className={classes.button}
                aria-label="like"
                onClick={() => ImageDelete(id)}
              >
                <DeleteIcon sx={{ color: "#4e4c4c" }} />
              </Fab>
            </div>
          </SortableItem>
        ))}
      </SortableList>
      <Box className={classes.btns}>
        <Button variant="contained" onClick={() => handleChangeOrder()}>
          Update
        </Button>
      </Box>

      <SuccessPop
        handleSuccesssPop={handleSuccesssPop}
        openSuccess={openSuccess}
        message={message}
      />
      <DeleteProduct
        handleSuccesssPop={handleDeletePop}
        openSuccess={openDelete}
        deleteProduct={handleDelete}
      />
    </>
  );
};

export default ImageView;
