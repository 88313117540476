import React, { useEffect, useState, useRef } from "react";
import { Button, Modal, ModalHeader, ModalBody, Row } from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import Leftsiderbar from "../home/allcmp/Leftsidebar";
import MaterialTable from "material-table";
import { API_URL, PRODUCT_S3 } from "../../../config";
import Top from "../Top";
import "./product.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { Box, TextField, Typography } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import EditProduct from "./EditProduct";
import DeleteProduct from "./DeleteProduct";

import axios from "axios";
import SuccessPop from "../SuccessPop/SuccessPop";
import ErrorPop from "../ErrorrPop/ErrorPop";
import CancelIcon from "@mui/icons-material/Cancel";
import { Multiselect } from "multiselect-react-dropdown";
import DetailPopUp from "./DetailPopUp";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ImagePop from "../ImagePop/ImagePop";
import AddImagePop from "../AddImagePop/AddImagePop";

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: "black",
    fontSize: "15px",
  },
  bin1: {
    margin: "5px 0 !important",
    padding: "15px",
    width: "100%",
    border: "1px solid #7c13867d !important",
    borderRadius: "8px ",
    background: "transparent",
    color: "#fff",
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&:focus-Visible": {
      outline: "none !important",
      border: "none !important",
    },
    "&:hover": {
      border: "none",
    },
    "& .MuiInputBase-root": {
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },

    "&:focus": {
      boxShadow: "none !important",
      border: "1px solid #7c13867d !important",
      borderRadius: "10px !important ",
    },
    "& input": {
      padding: "10px",
      fontSize: "15px",
      borderRadius: "10px",
      fontWeight: "500",
      "&::placeholder": {
        color: "#7C1386 !important",
        fontSize: "15px",
      },
    },
    "& textarea": {
      fontSize: "16px",
      color: "#7c13867d !important",
      padding: "10px !important",
      fontWeight: "500",
    },
  },
  btns: {
    textAlign: "center",
    margin: "10px 0",
    "& button": {
      fontSize: "15px",
      background: "#7C1386 !important",
      borderRadius: "10px",
      textTransform: "capitalize",
      color: "#fff",
      padding: "10px 30px",
      transition: "0.3s all !important",

      "&.Mui-disabled": {
        cursor: "not-allowed !important",
        pointerEvents: "auto !important",
        color: "rgb(255 255 255 / 38%) !important",
        "&:hover": {
          opacity: "1",
          transform: "scale(1) !important",
        },
      },
    },
  },
  error: {
    color: "#f14668",
    fontSize: "14px !important",
    paddingTop: "3px",
    wordBreak: "break-all",
  },
}));

const Products = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [changeExpirydateModal, setChangeExpirydateModal] = useState(false);
  const handleAdd = () => setChangeExpirydateModal(!changeExpirydateModal);
  const [openEdit, setOpenEdit] = useState(false);
  const handleEditOpen = () => setOpenEdit(true);
  const handleEditClose = () => setOpenEdit(false);
  const handleEditPop = () => setOpenEdit(!openEdit);
  const [openSuccess, setOpenSuccess] = useState(false);
  const handleSuccesssPop = () => setOpenSuccess(!openSuccess);
  const [openDelete, setOpenDelete] = useState(false);
  const handleDeletePop = () => setOpenDelete(!openDelete);
  const handleDeleteOpen = () => setOpenDelete(true);
  const [openImage, setOpenImage] = useState(false);
  const handleImagePop = () => setOpenImage(false);
  const handleImageOpen = () => setOpenImage(true);
  const [openAddImage, setOpenAddImage] = useState(false);
  const handleAddImagePop = () => setOpenAddImage(false);
  const handleAddImageOpen = () => setOpenAddImage(true);
  const [imageId, setImageId] = useState();
  const [openError, setOpenError] = useState(false);
  const handleErrorPop = () => setOpenError(!openError);
  const [openDetail, setOpenDetail] = useState(false);
  const handleDetail = () => setOpenDetail(!openDetail);
  const [productImage, setProductImage] = useState([]);
  const [selectCategroy, setSelectCategroy] = useState("Select Categroy");
  const [categroyData, setCategroyData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [message, setMessage] = useState("");
  const handleMesssage = (value) => setMessage(value);
  const [deleteId, setDeleteId] = useState("");
  const [multipleImageName, setMultipleImageName] = useState([]);
  const [isValidImageSize, setIsValidImageSize] = useState(false);
  const [selecteValue, setSelecteValue] = useState([]);
  const [valuesSelected, setValuesSelected] = useState([]);
  const [isSelecte, setIsSelecte] = useState(false);
  const [description, setDescription] = useState("");
  const [filterSelect, setFilterSelect] = useState("0");
  const [convertedContent, setConvertedContent] = useState(null);
  const [isDescription, setIsDescription] = useState(false);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };

  const handleChange = (e) => {
    // debugger
    setIsSelecte(false);
    setValuesSelected(e);
  };

  const validationSchema = yup.object({
    name: yup.string().required("Required").trim(),
    availability: yup.string().required("Required").trim(),
    insured_value: yup.string().required("Required").trim(),
    subtitle: yup.string().trim(),
    weight: yup.number().required("Required"),
    price: yup.number().required("Required"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      weight: "",
      availability: "",
      price: "",
      insured_value: "",
      subtitle: " ",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      // debugger

      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("weight", values.weight);
      formData.append("availability", values.availability);
      formData.append("price", values.price);
      formData.append("insured_value", values.insured_value);
      formData.append("description", convertedContent);
      formData.append("category", selectCategroy);
      formData.append("subtitle", values.subtitle);
      if (valuesSelected.length != 0) {
        for (let i = 0; i < valuesSelected.length; i++) {
          formData.append(`accessory_id[${i}]`, valuesSelected[i].id);
        }
      }
      for (let i = 0; i < productImage.length; i++) {
        formData.append(`form[${i}]`, productImage[i]);
      }
      try {
        const response = await axios.post(`${API_URL}/add/product`, formData);

        if (response.status === 200) {
          const resp = response.data;
          handleMesssage(resp.message);
          getProduct();
          handleAdd();
          handleSuccesssPop();
          resetForm();
          setSelectCategroy("");
          setProductImage("");
          setMultipleImageName("");
          setValuesSelected(" ");
        }
      } catch (error) {
        if (error.response.status === 400 || error.response.status === 404) {
          handleMesssage(error.response.data.message);
          handleErrorPop();
        } else {
          throw new Error("Network error");
        }
      }
    },
  });

  useEffect(() => {
    getAllCategroy();
    getAllProduct();
  }, []);

  useEffect(() => {
    getProduct();
  }, [filterSelect]);

  const getProduct = () => {
    let url = "/get/all/product";
    if (filterSelect && filterSelect != "0") {
      url = `/get/all/product?category=${filterSelect}`;
    } else {
      url = url;
    }
    fetch(API_URL + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      if (response.status == 200) {
        const resp = await response.json();
        let _temp = [];
        resp.data.map((v, i) => {
          _temp.push({
            Name: v.name,
            category_name: v.category_name,
            price: "$" + " " + v.price,
            image: PRODUCT_S3 + `/${v.product_images[0]}`,
            action: (
              <p className="action-p">
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip,
                  }}
                  title="Edit"
                  placement="top"
                >
                  <i
                    className="fa fa-edit action-icon"
                    onClick={() => handleEdit(v.id)}
                  ></i>
                </Tooltip>
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip,
                  }}
                  title="Delete"
                  placement="top"
                >
                  <i
                    className="fa fa-trash-o action-icon"
                    onClick={() => handleDelete(v.id)}
                  ></i>
                </Tooltip>
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip,
                  }}
                  title="Product Images"
                  placement="top"
                >
                  <i
                    className="fa fa-image action-icon"
                    onClick={() => handleImage(v.id)}
                  ></i>
                </Tooltip>
                <Tooltip
                  classes={{
                    tooltip: classes.customTooltip,
                  }}
                  title="Add Product Images"
                  placement="top"
                >
                  <i
                    className="fa fa-upload action-icon"
                    onClick={() => handleAddImage(v.id)}
                  ></i>
                </Tooltip>
              </p>
            ),
          });
        });
        let revData = _temp.reverse();
        setData(revData);
      } else if (response.status == 401) {
        logout();
      } else {
        alert("network error");
      }
    });
  };

  const getAllCategroy = () => {
    fetch(API_URL + "/get/all/product/category", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      if (response.status == 200) {
        const resp = await response.json();
        setCategroyData(resp.data);
      } else if (response.status == 401) {
        logout();
      } else {
        alert("network error");
      }
    });
  };
  const getAllProduct = () => {
    fetch(API_URL + "/get/all/product", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      if (response.status == 200) {
        const resp = await response.json();
        let _temp = [];
        resp?.data?.map((item, i) => {
          _temp.push({
            id: item.id,
            name: item.name,
          });
        });
        setSelecteValue(_temp);
      } else if (response.status == 401) {
        logout();
      } else {
        alert("network error");
      }
    });
  };

  const handleEdit = (id) => {
    handleEditOpen();
    getProductByID(id);
  };

  const getProductByID = (id) => {
    fetch(API_URL + `/get/product/by/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      if (response.status == 200) {
        const resp = await response.json();
        setUpdateData(resp.data);
      } else if (response.status == 401) {
        logout();
      } else {
        alert("network error");
      }
    });
  };

  const handleDelete = (id) => {
    handleDeleteOpen();
    setDeleteId(id);
  };

  const handleImage = (id) => {
    handleImageOpen();
    setImageId(id);
  };
  const handleAddImage = (id) => {
    handleAddImageOpen();
    setImageId(id);
  };

  const deleteProduct = () => {
    fetch(API_URL + `/delete/product/by/${deleteId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        getProduct();
        handleDeletePop();
        handleMesssage(data.message);
        handleSuccesssPop();
      })
      .catch((error) => {
        console.error("Error during DELETE request:", error);
      });
  };

  const handleAddProduct = () => {
    handleAdd();
  };

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const columns = [
    {
      title: "Image",
      field: "image",
      render: (rowData) => (
        <img
          src={rowData.image}
          alt="Image here"
          style={{ width: 50, borderRadius: "50%" }}
        />
      ),
    },
    {
      title: "Name",
      field: "Name",
    },
    {
      title: "Categories",
      field: "category_name",
    },
    {
      title: "Price",
      field: "price",
      cellStyle: {
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "center", // Align the header to the center
      },
    },
    {
      title: "Action",
      field: "action",
    },
  ];

  const handleProductImage = (event) => {
    setIsValidImageSize(false);
    const files = event.target.files;
    let newImages = [];
    let imageName = [];

    // Convert the FileList to an array and add it to the existing array
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Check if the file size is less than or equal to 5MB
      if (file.size <= 5 * 1024 * 1024) {
        imageName.push(...multipleImageName, file);
        newImages.push(...productImage, file);
      } else {
        setIsValidImageSize(true);
      }
    }

    setMultipleImageName(imageName);
    setProductImage(newImages);
  };

  const handleSelect = (event) => {
    setSelectCategroy(event.target.value);
  };

  const handleImageDelete = (name) => {
    setProductImage(productImage?.filter((image) => image?.name != name));
    setMultipleImageName(
      multipleImageName?.filter((image) => image?.name != name)
    );
  };

  const handleFilterSelect = (e) => {
    setFilterSelect(e.target.value);
  };

  return (
    <div className="">
      <div className="cmail">
        <Top />
      </div>
      <div className="wrp-dashbord">
        <div className="cemail-left">
          <Leftsiderbar />
        </div>
        <div className="right-section">
          <div className="onlineaccess-wrp">
            <div className="onlineaccess-child1">
              <h3>Products</h3>
            </div>
            <div className="add-btn">
              <button onClick={handleAddProduct}>Add Product</button>
            </div>
          </div>

          <Box
            sx={{
              width: "20%",
              display: "flex",
              flexDirection: "column",
              padding: "0.5rem 0rem 1rem 0rem",
              gap: 1,
              "@media(max-width:600px)": { width: "100% !important" },
            }}
          >
            <Typography
              component={"label"}
              sx={{ fontWeight: "600", color: "#7C1386" }}
            >
              Filter by category
            </Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              placeholder="Select Categroy"
              value={filterSelect}
              label="Categroy"
              onChange={(e) => handleFilterSelect(e)}
              sx={{
                border: "1px solid purple",
                "& .MuiSelect-select": {
                  padding: "10px !important",
                  border: "1px solid #7c13867d !important",
                },
              }}
            >
              <MenuItem value="0">All</MenuItem>
              {categroyData?.map((item, i) => (
                <MenuItem value={item.id}>{item.category_name}</MenuItem>
              ))}
            </Select>
          </Box>

          <div className="wrp-bankform">
            <div className="mobiletable">
              <div style={{ maxWidth: "100%" }}>
                <MaterialTable
                  options={{
                    search: true,
                    showTitle: false,
                    toolbar: true,
                    pageSize: 20,
                    //  pageSize: data?.length <= 5 ? 5 : data?.length > 5 && data?.length <= 10 ? 10 : data?.length > 10 && data?.length <= 20 ? 20 : data?.length > 20 && data?.length <= 30 ? 30 : data?.length > 30 && data?.length <= 40 ? 40 : data?.length > 40 && data?.length <= 50 ? 50 : data?.length > 50 && data?.length <= 100 ? 100 : 150,
                    pageSizeOptions: [5, 10, 20, 50, 100, 150, 200],
                  }}
                  columns={columns}
                  data={data}
                  title=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={changeExpirydateModal}
        toggle={handleAdd}
        className="connect-boxp"
        centered={true}
      >
        <ModalHeader toggle={handleAdd}>
          <span className="ml-1 roititle">Add Product</span>
        </ModalHeader>
        <ModalBody>
          <div className="changedate-box">
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  "@media(max-width:600px)": {
                    flexDirection: "column",
                    // gap:'10px !important'
                  },
                }}
              >
                <Box
                  sx={{
                    width: "48%",
                    "@media(max-width:600px)": { width: "100% !important" },
                  }}
                >
                  {/* row 1 left side  */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      "@media(max-width:600px)": {
                        flexDirection: "column",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        width: "48%",
                        "@media(max-width:600px)": { width: "100% !important" },
                      }}
                    >
                      <Typography
                        component={"label"}
                        sx={{ fontWeight: "600", color: "#7C1386" }}
                      >
                        Name<sup>*</sup>
                      </Typography>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="name"
                        name="name"
                        type="text"
                        className={classes.bin1}
                        value={formik.values.name}
                        placeholder="Enter product name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="standard"
                      />
                      {formik.touched.name && formik.errors.name && (
                        <Typography variant="body1" className={classes.error}>
                          {formik.errors.name}
                        </Typography>
                      )}
                    </Box>

                    <Box
                      sx={{
                        width: "48%",
                        "@media(max-width:600px)": { width: "100% !important" },
                      }}
                    >
                      <Typography
                        component={"label"}
                        sx={{ fontWeight: "600", color: "#7C1386" }}
                      >
                        Subtitle
                      </Typography>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="subtitle"
                        name="subtitle"
                        type="text"
                        className={classes.bin1}
                        value={formik.values.subtitle}
                        placeholder="Enter product subtitle"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="standard"
                      />
                      {formik.touched.subtitle && formik.errors.subtitle && (
                        <Typography variant="body1" className={classes.error}>
                          {formik.errors.subtitle}
                        </Typography>
                      )}
                    </Box>
                  </Box>

                  {/* row 2 leftside */}

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      "@media(max-width:600px)": {
                        flexDirection: "column",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        width: "48%",
                        "@media(max-width:600px)": { width: "100% !important" },
                      }}
                    >
                      <Typography
                        component={"label"}
                        sx={{ fontWeight: "600", color: "#7C1386" }}
                      >
                        Availability<sup>*</sup>
                      </Typography>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="availability"
                        name="availability"
                        type="text"
                        className={classes.bin1}
                        value={formik.values.availability}
                        placeholder="Enter product availability"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="standard"
                      />
                      {formik.touched.availability &&
                        formik.errors.availability && (
                          <Typography variant="body1" className={classes.error}>
                            {formik.errors.availability}
                          </Typography>
                        )}
                    </Box>

                    <Box
                      sx={{
                        width: "48%",
                        "@media(max-width:600px)": { width: "100% !important" },
                      }}
                    >
                      <Typography
                        component={"label"}
                        sx={{ fontWeight: "600", color: "#7C1386" }}
                      >
                        Price<sup>*</sup>
                      </Typography>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="price"
                        name="price"
                        type="number"
                        className={classes.bin1}
                        value={formik.values.price}
                        placeholder="Enter product price."
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="standard"
                      />
                      {formik.touched.price && formik.errors.price && (
                        <Typography variant="body1" className={classes.error}>
                          {formik.errors.price}
                        </Typography>
                      )}
                    </Box>
                  </Box>

                  {/* row 3 left side */}

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      "@media(max-width:600px)": {
                        flexDirection: "column",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        width: "48%",
                        "@media(max-width:600px)": { width: "100% !important" },
                      }}
                    >
                      <Typography
                        component={"label"}
                        sx={{ fontWeight: "600", color: "#7C1386" }}
                      >
                        Weight<sup>*</sup>
                      </Typography>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="weight"
                        name="weight"
                        type="number"
                        className={classes.bin1}
                        value={formik.values.weight}
                        placeholder="Enter product weight"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="standard"
                      />
                      {formik.touched.weight && formik.errors.weight && (
                        <Typography variant="body1" className={classes.error}>
                          {formik.errors.weight}
                        </Typography>
                      )}
                    </Box>

                    <Box
                      sx={{
                        width: "48%",
                        "@media(max-width:600px)": { width: "100% !important" },
                      }}
                    >
                      <Typography
                        component={"label"}
                        sx={{ fontWeight: "600", color: "#7C1386" }}
                      >
                        Insured value<sup>*</sup>
                      </Typography>
                      <TextField
                        autoComplete="off"
                        fullWidth
                        id="description"
                        name="insured_value"
                        type="number"
                        className={classes.bin1}
                        value={formik.values.insured_value}
                        placeholder="Enter product Insured value"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="standard"
                      />
                      {formik.touched.insured_value &&
                        formik.errors.insured_value && (
                          <Typography variant="body1" className={classes.error}>
                            {formik.errors.insured_value}
                          </Typography>
                        )}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                      "@media(max-width:600px)": { width: "100% !important" },
                    }}
                  >
                    <Typography
                      component={"label"}
                      sx={{ fontWeight: "600", color: "#7C1386" }}
                    >
                      Category<sup>*</sup>
                    </Typography>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      placeholder="Select Categroy"
                      value={selectCategroy}
                      label="Categroy"
                      onChange={(e) => handleSelect(e)}
                      sx={{
                        border: "1px solid purple",
                        "& .MuiSelect-select": {
                          padding: "10px !important",
                          border: "1px solid #7c13867d !important",
                        },
                      }}
                    >
                      {categroyData?.map((item, i) => (
                        <MenuItem value={item.id}>
                          {item.category_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                      paddingTop: "0.4rem",
                      "@media (max-width:600px)": {
                        paddingTop: "0rem",
                      },
                      "& .multiSelectContainer": {
                        border: "1px solid #7C1386",
                        borderRadius: "0.3rem",
                      },
                      "& .highlightOption": {
                        background: "#800080 !important",
                      },
                      "& .multiSelectContainer li:hover": {
                        background: "#800080 !important",
                      },
                      "& .chip": {
                        background: "#800080 !important",
                      },
                      "@media(max-width:600px)": { width: "100% !important" },
                    }}
                  >
                    <Typography
                      component={"label"}
                      sx={{ fontWeight: "600", color: "#7C1386" }}
                    >
                      Accessory
                    </Typography>
                    <Multiselect
                      options={selecteValue}
                      // selectedValues={_tempdistributor}
                      displayValue="name"
                      onSelect={handleChange}
                      onRemove={handleChange}
                    />
                    {isSelecte && (
                      <Typography component={"p"} className={classes.error}>
                        Required
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{
                      padding: "0.4rem 0rem",
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      component={"label"}
                      sx={{ fontWeight: "600", color: "#7C1386" }}
                    >
                      Product Image<sup>*</sup>
                    </Typography>
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      required
                      onChange={handleProductImage}
                      style={{
                        width: "100%",
                        border: "1px solid purple",
                        padding: "0.5rem",
                        borderRadius: "8px",
                      }}
                    />
                    {isValidImageSize && (
                      <Typography component={"p"} className={classes.error}>
                        File size should be 5MB or less.
                      </Typography>
                    )}
                    {multipleImageName &&
                      multipleImageName?.map((item, i) => (
                        <>
                          {item?.name != undefined && (
                            <Typography
                              component={"p"}
                              sx={{
                                width: "fit-content",
                                overflow: "auto",
                                padding: "0.2rem 0.3rem",
                                borderRadius: "0.5rem",
                                border: "1px solid purple",
                              }}
                            >
                              <Typography component={"span"}>
                                {item?.name}
                              </Typography>{" "}
                              <CancelIcon
                                sx={{ color: "black" }}
                                onClick={() => handleImageDelete(item?.name)}
                              />
                            </Typography>
                          )}
                        </>
                      ))}
                  </Box>
                </Box>

                {/* unused code  */}
                <Box
                  sx={{
                    width: "48%",
                    "@media(max-width:600px)": { width: "100% !important" },
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      // paddingTop: "0.4rem",
                      "@media(max-width:600px)": { width: "100% !important" },
                    }}
                  >
                    <Typography
                      component={"label"}
                      sx={{
                        fontWeight: "600",
                        color: "#7C1386",
                        paddingBottom: "0.38rem",
                      }}
                    >
                      Description
                    </Typography>
                    <Box
                      sx={{
                        border: "1px solid purple",
                        borderRadius: "4px",
                        height: "448px",
                        overflow:'auto',
                        width: "100%",
                        "& .rdw-editor-main": {
                          padding: "0rem 0.5rem",

                        },
                        "& a": {
                          color: "purple !important",
                        },
                        "& a:hover": {
                          color: "purple !important",
                        },
                      }}
                    >
                      <Editor
                        editorState={editorState}
                        onEditorStateChange={handleEditorChange}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"

                      />
                    </Box>
                    {isDescription && (
                      <Typography component={"p"}>Required</Typography>
                    )}
                  </Box>
                </Box>
              </Box>

              <Box className={classes.btns}>
                <Button
                  type="submit"
                  disabled={!(formik.isValid && formik.dirty)}
                >
                  Submit
                </Button>
              </Box>
            </form>
          </div>
        </ModalBody>
      </Modal>
      <EditProduct
        handleEditPop={handleEditPop}
        openEdit={openEdit}
        updateData={updateData}
        categroyData={categroyData}
        getProduct={getProduct}
        handleMesssage={handleMesssage}
        handleSuccesssPop={handleSuccesssPop}
        handleErrorPop={handleErrorPop}
        handleEditOpen={handleEditOpen}
        handleEditClose={handleEditClose}
      />
      <SuccessPop
        handleSuccesssPop={handleSuccesssPop}
        openSuccess={openSuccess}
        message={message}
      />
      <ErrorPop
        handleErrorPop={handleErrorPop}
        openError={openError}
        message={message}
      />
      <ImagePop
        handleErrorPop={handleImagePop}
        openError={openImage}
        imageId={imageId}
        getProduct={getProduct}
      />
      <AddImagePop
        handleErrorPop={handleAddImagePop}
        openError={openAddImage}
        imageId={imageId}
        getProduct={getProduct}
      />
      <DetailPopUp
        handleErrorPop={handleDetail}
        openError={openDetail}
        message={description}
      />
      <DeleteProduct
        handleSuccesssPop={handleDeletePop}
        openSuccess={openDelete}
        deleteProduct={deleteProduct}
      />
    </div>
  );
};

export default Products;
