import React, { useEffect, useState } from 'react';
import { Link, useParams, Router } from 'react-router-dom';
import Leftsiderbar from '../home/allcmp/Leftsidebar'
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from '@material-ui/core';
import Header from '../header';
import MaterialTable from 'material-table';
import download from '../../images/download.png'
import preveiw from '../../images/preveiw.png'
import Delete from '../../images/delete.png';
import { API_URL } from '../../../config';
import edit from '../../images/edit.png'
import listicon from '../../images/listicon.png'
import Top from '../Top';

import { Button, Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import { CompressOutlined } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
    customTooltip: {
      backgroundColor: "black",
      fontSize: "15px"
    }
  }));

const List = () => {

    const classes = useStyles();

    const [data, setData] = useState([]);
    const accessToken = localStorage.getItem('accessToken');
    const [editModal, setEditModal] = useState(false);
    const EditToggleModal = () => setEditModal(!editModal);
    const [emailList, setEmailList] = useState([]);
    const [name, setName] = useState([]);
    const [expirydate, setExpirydate] = useState([]);
    const [ownerSerial, setOwnerSerial] = useState([]);

   


    const getEmailsList = (_domain) => {
        fetch(API_URL + "/get/group/list/" + _domain,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken,
                },
            }
        ).then(async (response) => {
            if (response.status == 200) {
                const resp = await response.json();
                if (resp.success) {
                    let _data = {};
                    let _list = [];
                    let _fullName = [];
                    let _ExpiryDate = [];

                    resp.data.map((v, i) => {
                        _list.push(v.email);
                        _fullName.push(v.firstname + " " + v.lastname);
                        _ExpiryDate.push(new Date(v.expire_account * 1000).toLocaleDateString());

                        fetch(API_URL + "/get/ownerid/list/" + v.id,
                            {
                                method: 'GET',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'x-access-token': accessToken,
                                },
                            }
                        ).then(async (response) => {
                            if (response.status == 200) {
                                const resp = await response.json();

                                // let _OwnerSerial = ownerSerial
                                // _OwnerSerial.push(resp.OwnerSerial);

                                setOwnerSerial(prevState => [...prevState, resp.OwnerSerial])


                            }

                            else {
                                console.log("network error")
                            }


                        })
                    })


                    setEmailList(_list)

                    setName(_fullName)
                    setExpirydate(_ExpiryDate)

                    EditToggleModal()
                    // console.log(_data)


                }
                else {
                    // alert("no emails found")        
                }

            }
            else if (response.status == 401) {
                logout()
            }
            else {
                alert("network error")
            }


        })
    }


    const showList = (_list) => {
        // alert(_list);
        _list = _list.split(",");
        setEmailList(_list)
        EditToggleModal()
    }
    const getEmails = () => {
        fetch(API_URL + "/get/group/emails",
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken,
                },
            }
        ).then(async (response) => {
            if (response.status == 200) {
                const resp = await response.json();
                if (resp.success) {
                    let _data = {};
                    let _dataAray = [];
                    resp.data.map((v, i) => {
                        _data = v;

                        _data['price'] = v.price ? v.price : "Default";

                        _data['business'] = v.business ? v.business : "NA";

                        let _emails = (v.email);
                        let _emailCount = v.email.split(',');
                        _emailCount = _emailCount.length;
                        let _domain = v.primaryEmail.split("@");

                        _data['action'] = _emails == "all" ? <Tooltip classes={{
                            tooltip: classes.customTooltip,
                            
                          }} title="View" placement="top"><a onClick={() => getEmailsList(_domain[1])} className="downloadimg"><img src={listicon} /></a></Tooltip> : <Tooltip classes={{
                            tooltip: classes.customTooltip,
                            
                          }} title="View" placement="top"><a onClick={() => showList(_emails)} className="downloadimg"><img src={listicon} /></a></Tooltip>;
                        _data['email'] = v.email == "all" ? "All emails by default" : _emailCount;
                        // _data['primaryEmail'] = fullName + " " + "(" + _domain + ")"  + ExpiryDate;

                        _dataAray.push(_data);


                    })
                    setData(_dataAray);
                    // console.log(_data)
                }
                else {
                    // alert("no emails found")        
                }

            }
            else if (response.status == 401) {
                logout()
            }
            else {
                alert("network error")
            }


        })
    }

    const logout = () => {
        localStorage.clear();
        window.location.reload();
    }

    useEffect(() => {
        getEmails()
    }, [])

    const columns = [
        {
            title: "Primary Email", field: "primaryEmail"
        },
        {
            title: "Emails Count", field: "email"
        },
        {
            title: "Business Name", field: "business"
        },

        {
            title: "($) Price", field: "price"
        },
        {
            title: <span className="text-right">View List</span>, field: "action"
        }
    ]

    const gotoAdd = () => {
        window.location.href = "/add/list"
    }

    return (
        <div className="">
            <div className="cmail">
                <Top />
            </div>
            <div className="wrp-dashbord">
                <div className="cemail-left">
                    <Leftsiderbar />
                </div>
                <div className="right-section">
                    <div className="add-btn-wrp add-btn-wrp2">
                        <div className="head-listing">
                            <h3>Group Subscriptions</h3>
                        </div>
                        <div className="add-btn">
                            <button onClick={gotoAdd}>Add</button>
                        </div>
                    </div>
                    <div className="wrp-bankform">
                        <div className='mobiletable'>
                        <div style={{ maxWidth: '100%' }}>
                            <MaterialTable
                                options={{
                                    search: true,
                                    showTitle: false,
                                    toolbar: true,
                                }}
                                columns={columns}
                                data={data}
                                title=""
                            />

                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={editModal} toggle={EditToggleModal} className="connect-box" centered={true}>
                <ModalHeader toggle={EditToggleModal}><span className="ml-1 roititle font-weight-bold">Members ({emailList.length})</span></ModalHeader>
                <ModalBody className='scrollOver'>
                    <div className="modal-p">
                        {/* <h4>Edit</h4> */}
                        <ul className='emaillist'>
                            {
                                emailList.length > 0 && emailList.map((v, i) => {
                                    return (
                                        <li><p><span className='emailListName'>Name</span> <br></br>{name[i]} ({v}) <br></br> <span className='expirydate'>Expiry Date</span> <br></br> {expirydate[i]?expirydate[i]: "No Found"}<br></br> <span className='ownerSerial'>Device Count</span><br></br> {ownerSerial[i]?ownerSerial[i]: "No Found"}</p></li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </ModalBody>

            </Modal>
        </div>
    )
}

export default List;