import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Box, FormControl, Grid, NativeSelect, TextField, Typography } from "@mui/material";
import { API_URL } from "../../../config";




const AffiliateViewModal = ({
    openView,
  handleViewPop,
  updateData,

}) => {

const viewData=updateData?.[0];
const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const getCountryList = (id) => {
    fetch(API_URL + `/countries`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      if (response.status == 200) {
        const resp = await response.json();
     setCountryList(resp.countries)
      } else {
        console.log("network error");
      }
    });
  };
  const getStateList = (id) => {
    fetch(API_URL + `/states?country_id=${viewData?.country}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      if (response.status == 200) {
        const resp = await response.json();
        setStateList(resp.states)
      } else {
        console.log("network error");
      }
    });
  };
useEffect(()=>{
  getCountryList()
},[])
useEffect(()=>{
  getStateList()
},[viewData?.country])

const getCountryNameById = (id) => {
  const country = countryList.filter((country) => country.id == id);
  return country.length > 0 ? country.map((country) => country.name)[0] : "";
};
const getStateNameById = (id) => {
  const state = stateList.filter((state) => state.id == id);
  return state.length > 0 ? state.map((state) => state.name)[0] : "";
};
  return (
    <>
      <Modal
        isOpen={openView}
        toggle={handleViewPop}
        className="connect-boxSupport"
        centered={true}
        style={{maxWidth:"600px"}}
      >
        <ModalHeader toggle={handleViewPop}>
          <span className="ml-1 roititle">View Affiliate</span>
        </ModalHeader>
        <ModalBody>
          <Box className="changedate-box" sx={{
            "& p":{
                fontSize:"1rem",
                p:"10px",
                mb:"5px",
                border:"1px dashed #000",
                borderRadius:"5px"
            }
          }}>
          <Typography>First Name: {viewData?.firstName}</Typography>
          <Typography>Last Name: {viewData?.lastName}</Typography>
          <Typography>Email: {viewData?.email}</Typography>
          <Typography>Username: {viewData?.username}</Typography>
          <Typography>Phone No.: {viewData?.phone || "NA"}</Typography>
          <Typography>Commission: {viewData?.comission||"NA"} %</Typography>
          <Typography>Business Name: {viewData?.business || "NA"}</Typography>
          <Typography>City: {viewData?.city || "NA"}</Typography>
          <Typography>State: {getStateNameById(viewData?.state) || "NA"} </Typography>
            <Typography>Country: {getCountryNameById(viewData?.country) || "NA"} </Typography>
          </Box>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AffiliateViewModal;
